<template>
  <div class="flex gap-4 mx-5">
    <LeftNav class="mt-[20px] mr-[10px]" />
    <div class="min-h-[calc(100vh-40px)] h-full mt-[20px] w-full">
      <div class="flex flex-col gap-2 w-full">
        <TopNav />
        <div class="w-full h-[90vh] pb-3 overflow-auto">
          <div class="py-3">
            <div class="bg-white px-6 pb-6 rounded-lg shadow-lg">
              <div class="text-lg flex justify-between py-3">
                <p>Organization User List</p>
                <div class="flex gap-x-4">
                  <button
                    @click="toggleAddUser"
                    class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-transform hover:bg-[#76bdeb] shadow-md"
                  >
                    Add User
                  </button>
                  <button
                    @click="toggleAddRole"
                    class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-transform hover:bg-[#76bdeb] shadow-md"
                  >
                    Add Role
                  </button>
                  <button
                    @click="toggleAddCategory"
                    class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-transform hover:bg-[#76bdeb] shadow-md"
                  >
                    Add Category
                  </button>
                </div>
              </div>
              <div class="">
                <table class="min-w-full border-collapse">
                  <!-- Table Header -->
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        User Name
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        Category
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        Level
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr
                      v-for="user in userList"
                      :key="user.id"
                      class="hover:bg-gray-200 text-sm"
                    >
                      <td
                        class="px-3 py-1 text-wrap w-56 text-start border border-gray-300 capitalize"
                      >
                        {{ user.user?.display_name || "N/A" }}
                      </td>
                      <td
                        class="px-3 py-1 text-wrap w-56 text-start border border-gray-300"
                      >
                        {{ user.user_role?.role_category?.name || "N/A" }}
                      </td>
                      <td
                        class="px-3 py-1 text-wrap w-56 text-start border border-gray-300"
                      >
                        {{ user.user_role?.name || "N/A" }}
                      </td>
                      <td
                        class="px-3 py-1 text-wrap w-20 text-start border border-gray-300"
                      >
                        {{ user.user_role?.role_level?.name || "N/A" }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  v-if="userList.length === 0"
                  class="bg-white flex w-full py-5 justify-center"
                >
                  No User List Available !!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OrganizationUserAdd
    v-if="userAddForm"
    :showAddUser="userAddForm"
    @close-form="toggleAddUser"
    @refresh="this.organizationUserList()"
    @formSubmit="handleFormSubmit"
  />
  <CreateRole
    v-if="createRole"
    :showRoleCreate="createRole"
    @close-form="toggleAddRole"
    @refresh="this.organizationUserList()"
    @formSubmit="handleFormSubmit"
  />
  <CreateCategory
    v-if="createCategory"
    :showCategoryCreate="createCategory"
    @close-form="toggleAddCategory"
    @refresh="this.organizationUserList()"
    @formSubmit="handleFormSubmit"
  />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import CreateCategory from "@/components/Organization/CreateCategory.vue";
import CreateRole from "@/components/Organization/CreateRole.vue";
import OrganizationUserAdd from "@/components/Organization/OrganizationUserAdd.vue";
import LeftNav from "@/components/LeftNavigation/LeftNav.vue";
import TopNav from "@/components/TopNav.vue";

export default {
  name: "MyTasks",
  components: {
    LeftNav,
    TopNav,
    OrganizationUserAdd,
    CreateRole,
    CreateCategory,
  },

  computed: {
    ...mapGetters([
      "getUser",
      "getOrgUser",
      "getSelectedOrganization",
      "getAccessToken",
    ]),
  },
  data() {
    return {
      userList: [],
      userAddForm: false,
      createRole: false,
      createCategory: false,
    };
  },
  created() {
    this.organizationUserList(this.getSelectedOrganization.organization_id);
  },
  mounted() {},
  methods: {
    toggleAddCategory() {
      this.createCategory = !this.createCategory;
    },
    toggleAddRole() {
      this.createRole = !this.createRole;
    },
    toggleAddUser() {
      this.userAddForm = !this.userAddForm;
    },
    async organizationUserList(organization_id) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/organization-user-level/list/?organization_id=${organization_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        if (response.data && Array.isArray(response.data.data)) {
          this.userList = response.data.data;
          console.log("Role List:", this.userList);

          this.userList.forEach((user) => {
            if (!user.user_role?.role_category?.name) {
              console.warn("Missing role category for user:", user);
            }
          });
        } else {
          console.error("Unexpected response structure:", response.data);
          this.userList = [];
        }
      } catch (error) {
        console.error("Error fetching user roles:", error);
      }
    },
  },
};
</script>
