<template>
  <div
    class="min-h-[calc(100vh-40px)] h-full mt-[20px] w-full flex flex-col gap-2 px-5"
  >
    <TopNav />
    <div class="p-4 rounded-xl shadow-md overflow-auto bg-white mb-4">
      <div class="items-center justify-between flex">
        <div class="flex items-center">
          <router-link :to="{ name: 'HomeView' }" class="hover:underline">
            Dashboard
          </router-link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="size-4 text-blue-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
          <router-link
            :to="{ name: 'ProjectDashboard', params: { slug: slug } }"
            class="hover:underline"
          >
            {{ projectName }}
          </router-link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="size-4 text-blue-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
          Board View
        </div>
        <div class="flex gap-x-3">
          <!-- <button
            @click="toggleCardForm"
            class="bg-soft-primary border border-primary text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-all shadow-lg"
          >
            Add card
          </button>
          <button
            @click="toggleFlagForm"
            class="bg-soft-primary border border-primary text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-all shadow-lg"
          >
            Create Flag
          </button> -->
          <div class="relative" ref="dropdownContainer">
            <button
              ref="optionsArchievedButton"
              @click="toggleArchievedDropdown"
              class="h-8 w-8 rounded-full flex items-center justify-center bg-white"
            >
              <svg
                class="h-6 w-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="6" r="1.5" fill="#000000" />
                <circle cx="12" cy="12" r="1.5" fill="#000000" />
                <circle cx="12" cy="18" r="1.5" fill="#000000" />
              </svg>
            </button>
            <div
              v-if="showDropDown"
              class="dropdown absolute mt-2 right-0 w-36 z-[99] rounded-md shadow-lg bg-white border border-gray-200"
            >
              <ul class="p-2 space-y-1">
                <li @click="handleArchivedOption" class="w-full">
                  <button
                    @click="toggleArchieved"
                    class="w-full flex items-center gap-x-1 py-1 px-1 text-sm font-medium text-gray-700 hover:bg-blue-100 hover:text-blue-600 transition duration-150 ease-in-out rounded-md whitespace-nowrap"
                  >
                    <svg
                      class="w-6 h-6"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12"
                        stroke="#1C274C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14Z"
                        stroke="#1C274C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M12 11L12 17M12 17L14.5 14.5M12 17L9.5 14.5"
                        stroke="#00000098"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="text-sm cursor-pointer">Archived Tasks</span>
                  </button>
                </li>
                <li
                  v-if="
                    this.getSelectedOrganization.user_level == 'Level 1' ||
                    this.getSelectedOrganization.user_level == 'Level 2' ||
                    this.getSelectedOrganization.user_level == 'Level 3'
                  "
                  @click="handleArchivedOption"
                  class="w-full"
                >
                  <button
                    @click="toggleArchievedCard"
                    class="w-full flex items-center gap-x-1 py-1 px-1 text-sm font-medium text-gray-700 hover:bg-blue-100 hover:text-blue-600 transition duration-150 ease-in-out rounded-md whitespace-nowrap"
                  >
                    <svg
                      class="w-6 h-6"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12"
                        stroke="#1C274C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14Z"
                        stroke="#1C274C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M12 11L12 17M12 17L14.5 14.5M12 17L9.5 14.5"
                        stroke="#00000098"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="text-sm cursor-pointer">Archived Cards</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- <div v-if="toggleFlag" class="absolute right-2 mt-1 w-72">
        <CreateFlag
          :toggleFlag="toggleFlag"
          :toggleFlagForm="toggleFlagForm"
          :projectId="projectId"
          :projectCards="projectCards"
          @close-form="toggleFlagForm"
        />
      </div> -->

      <div class="overflow-auto gap-5 flex py-4 min-h-[285px] max-h-full">
        <div v-for="card in projectCards" :key="card.id" class="h-full gap-3">
          <div
            class="overflow-auto bg-slate-200 rounded-xl p-3 2xl:w-[300px] lg:w-[279px] md:w-[200px]"
          >
            <div>
              <h3
                v-if="editingCardId !== card.id"
                class="rounded-xl flex items-center justify-between px-4 text-black font-normal text-lg h-16"
              >
                <span class="flex gap-2 items-center capitalize"
                  >{{ card.name }}

                  <img
                    v-if="
                      this.getSelectedOrganization.user_level == 'Level 1' ||
                      this.getSelectedOrganization.user_level == 'Level 2' ||
                      this.getSelectedOrganization.user_level == 'Level 3'
                    "
                    @click="startEditingCard(card)"
                    src="../assets/edit.svg"
                    class="w-5 cursor-pointer"
                  />
                </span>

                <div class="relative">
                  <button
                    ref="optionsButton"
                    @click="toggleDropdown(card.id)"
                    class="h-8 w-8 rounded-full flex items-center justify-center bg-white"
                    id="options-button"
                  >
                    <svg
                      class="h-6 w-6"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="6" r="1.5" fill="#000000" />
                      <circle cx="12" cy="12" r="1.5" fill="#000000" />
                      <circle cx="12" cy="18" r="1.5" fill="#000000" />
                    </svg>
                  </button>

                  <!-- Dropdown menu -->
                  <div
                    v-if="dropdownOpen && selectedProjectId === card.id"
                    class="dropdown absolute mt-2 right-0 w-36 z-[99] rounded-md shadow-lg bg-white border border-gray-200"
                  >
                    <ul class="p-1">
                      <!-- Add Task Option -->
                      <li @click="handleOptionClick">
                        <button
                          @click="toggleTaskForm(card)"
                          class="w-full flex items-center gap-x-2 py-2 px-4 text-sm font-medium text-gray-700 hover:bg-blue-100 hover:text-blue-600 transition duration-150 ease-in-out rounded-md"
                        >
                          <svg
                            class="w-5 h-5 text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 12H12M12 12H18M12 12V18M12 12V6"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span>Add Task</span>
                        </button>
                      </li>

                      <!-- Archive Option -->
                      <li
                        v-if="
                          this.getSelectedOrganization.user_level ==
                            'Level 1' ||
                          this.getSelectedOrganization.user_level ==
                            'Level 2' ||
                          this.getSelectedOrganization.user_level == 'Level 3'
                        "
                        @click="handleOptionClick"
                      >
                        <button
                          @click="archiveCard(card.id)"
                          class="w-full flex items-center gap-x-2 py-2 px-4 text-sm font-medium text-gray-700 hover:bg-red-100 hover:text-red-600 transition duration-150 ease-in-out rounded-md"
                        >
                          <svg
                            class="w-5 h-5 text-red-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12"
                              stroke="currentColor"
                              stroke-width="1.5"
                            />
                            <path
                              d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14Z"
                              stroke="currentColor"
                              stroke-width="1.5"
                            />
                            <path
                              d="M12 11L12 17M12 17L14.5 14.5M12 17L9.5 14.5"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span>Archive</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </h3>
              <h3
                v-if="editingCardId === card.id"
                class="rounded-xl flex flex-col justify-center relative text-black font-normal text-lg h-16"
              >
                <input
                  v-model="card.name"
                  type="text"
                  class="w-full p-1 rounded-lg pr-16"
                />
                <div class="flex justify-end absolute right-5 top-4.4 gap-1">
                  <svg
                    @click="cancelEditCard"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-7 bg-red-500 text-white p-1 rounded-sm cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                  <svg
                    @click="saveCardName(card)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-7 bg-green-500 text-white p-1 rounded-sm cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </div>
              </h3>
            </div>
            <draggable
              class="dragArea list-group w-full flex-grow"
              tag="ul"
              group="task"
              :list="card.project_card_task"
              v-bind="dragOptions"
              @end="updateTaskOrder"
              @dragover="cardDragOver(card.id)"
              @dragstart="cardDragStart(card.id)"
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' + card.id : null"
              >
                <li
                  id="taskCardWithId"
                  @dragover="taskDragOver(task.id, index)"
                  @dragstart="taskDragStart(task.id, index)"
                  @click="ShowTaskDetails(task, task.id)"
                  class="text-start rounded-xl my-3 bg-white cursor-all-scroll text-black relative"
                  v-for="(task, index) in card.project_card_task"
                  :class="[
                    'bg-white',
                    todayDate > new Date(task.end_date) && !task.is_completed
                      ? 'bg-red-50'
                      : 'bg-white',
                  ]"
                  :key="task.id"
                >
                  <p
                    class="p-2 px-3 text-gray-700 break-words taskTitle"
                    style="line-height: 20px"
                  >
                    {{ task.name }}
                  </p>
                  <hr />

                  <img
                    v-if="task.document"
                    :src="getSecureUrl(task.document)"
                    alt="task image"
                    class="min-h-full max-h-48 mx-auto w-full object-top object-cover"
                  />
                  <pre
                    class="px-3 py-1 text-sm taskTitle text-wrap font-sans break-words whitespace-pre-line text-gray-600"
                    style="line-height: 16px"
                  >
                    {{ task.description }}
                  </pre>
                  <div class="flex flex-col mt-0 gap-y-1 pb-3">
                    <span class="px-3 flex justify-between items-center">
                      <span
                        v-if="task.end_date"
                        class="text-xs flex items-center gap-x-2"
                      >
                        <img src="@/assets/calendar.svg" class="w-4" alt="" />
                        <span>
                          {{ formatDateTime(task.end_date) }}
                        </span>
                      </span>
                      <span
                        v-if="task.flag?.name"
                        class="flex items-center bg-gray-100 px-2 py-1 ml-auto rounded-md"
                      >
                        <svg
                          width="50px"
                          height="50px"
                          stroke-width="2.5"
                          viewBox="0 0 64 64"
                          xmlns="http://www.w3.org/2000/svg"
                          :fill="task.flag?.colour_code"
                          :stroke="task.flag?.colour_code"
                          class="size-5"
                        >
                          <polyline
                            points="16 32 48 32 36 20 48 8 16 8 16 56"
                          />
                        </svg>
                        <span class="text-xs">{{ task.flag?.name }}</span>
                      </span>
                    </span>

                    <div
                      class="font-semibold items-center flex justify-between px-3"
                    >
                      <span class="flex gap-x-1">
                        <img src="@/assets/assignedto.svg" class="h-4" alt="" />
                        <span
                          :class="[
                            task.assigned_to
                              ? 'text-gray-600 capitalize text-xs'
                              : 'text-gray-600 text-xs font-light',
                          ]"
                        >
                          {{
                            task.assigned_to
                              ? task.assigned_to?.user.display_name
                              : "Not assigned"
                          }}
                        </span>
                      </span>
                      <span
                        v-if="task.task_subtask.length > 0"
                        class="flex gap-1 items-start w-8 ml-auto mr-3"
                      >
                        <svg
                          width="80px"
                          height="80px"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke-width="1.9"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-4 h-4"
                        >
                          <rect
                            x="18"
                            y="9"
                            width="4"
                            height="4"
                            rx="2"
                            transform="rotate(90 18 9)"
                            stroke="#222222"
                          />
                          <rect
                            x="18"
                            y="17"
                            width="4"
                            height="4"
                            rx="2"
                            transform="rotate(90 18 17)"
                            stroke="#222222"
                          />
                          <rect
                            x="3"
                            y="7"
                            width="4"
                            height="4"
                            rx="2"
                            transform="rotate(-90 3 7)"
                            stroke="#222222"
                          />
                          <path
                            d="M5 8V15C5 16.8856 5 17.8284 5.58579 18.4142C6.17157 19 7.11438 19 9 19H14"
                            stroke="#222222"
                          />
                          <path
                            d="M5 7V7C5 8.88562 5 9.82843 5.58579 10.4142C6.17157 11 7.11438 11 9 11H14"
                            stroke="#222222"
                          />
                        </svg>
                        <b class="text-sm text-gray-600">{{
                          task.task_subtask.length
                        }}</b>
                      </span>
                      <div
                        @click.stop
                        class="flex items-end pr-2 pb-[13.5px] justify-end absolute bottom-0 w-10 h-10 z-99 right-0"
                      >
                        <input
                          type="checkbox"
                          id="task_status"
                          :checked="task.is_completed"
                          @change="toggleCompletionStatus(task.id, $event)"
                          class="w-4 h-4 text-blue-500 appearance-none taskcheckbox border-2 border-gray-300 rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </transition-group>
            </draggable>
            <div
              @click="toggleTaskForm(card)"
              class="h-10 w-full rounded-xl flex justify-center items-center cursor-pointer bg-gray-50"
            >
              Add Task
            </div>
          </div>
        </div>
        <div
          v-if="
            this.getSelectedOrganization.user_level == 'Level 1' ||
            this.getSelectedOrganization.user_level == 'Level 2' ||
            this.getSelectedOrganization.user_level == 'Level 3'
          "
          class="relative"
        >
          <div
            @click="toggleCardForm"
            class="bg-gray-100 cursor-pointer border border-dashed border-gray-500 rounded-xl flex justify-center items-center 2xl:w-[300px] lg:w-[279px] md:w-[200px] h-[86px]"
          >
            <span class="flex gap-2 items-center text-black capitalize"
              >Add Card
            </span>
          </div>
          <!-- Add Card Form -->
          <div v-if="toggleCard" class="">
            <WorkSpaceAddCard
              :toggleCard="toggleCard"
              :toggleCardForm="toggleCardForm"
              :projectId="projectId"
              :projectCards="projectCards"
              @cardSubmit="cardSubmit"
              @close-form="toggleCardForm"
            />
          </div>
        </div>
        <WorkspaceAddTask
          v-if="taskForm"
          :card="cardId"
          :flagList="flagList"
          :showTaskForm="taskForm"
          @close-form="toggleTaskForm"
          @formSubmit="handleFormSubmit"
          :getOrgUser="getOrgUser"
          :memberList="memberList"
        />
        <TaskDetails
          v-if="showCanvasDetails"
          :flagList="flagList"
          :showtaskDetails="showtaskDetails"
          :slug="slug"
          :memberList="memberList"
          :projectId="projectId"
          @closeTaskCanvas="toggleTaskDetails"
          @updateTask="updateTask"
          :task_id="task_id"
          @closeCanvasDetails="toggleCanvasdetails"
          @refreshTaskDetails="handleAssigned"
        />
        <ArchievedTaskList
          v-if="archievedTask"
          :showarchievedTask="archievedTask"
          @close-form="toggleArchieved"
          @refresh="this.workspace()"
          :projectId="projectId"
          :flagList="flagList"
        />
        <ArchievedCardList
          v-if="archievedCard"
          :showarchievedCard="archievedCard"
          @close-form="toggleArchievedCard"
          @refresh="this.workspace()"
          :projectId="projectId"
          :flagList="flagList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";
import TopNav from "@/components/TopNav.vue";
import axios from "axios";
import WorkspaceAddTask from "@/components/WorkspaceAddTask.vue";
import WorkSpaceAddCard from "@/components/WorkSpaceAddCard.vue";
import TaskDetails from "@/components/Task/TaskDetails.vue";
import ArchievedTaskList from "@/components/ArchievedTaskList.vue";
import ArchievedCardList from "@/components/ArchievedCardList.vue";
// import CreateFlag from "@/components/CreateFlag.vue";

export default {
  name: "WorkspaceView",
  components: {
    draggable: VueDraggableNext,
    TopNav,
    WorkspaceAddTask,
    WorkSpaceAddCard,
    TaskDetails,
    ArchievedTaskList,
    ArchievedCardList,
  },

  data() {
    return {
      todayDate: new Date(),
      dropdownOpen: false,
      showDropDown: false,
      taskDetailsKey: 0,
      drag: false,
      toggleCard: false,
      toggleFlag: false,
      taskForm: false,
      archievedTask: false,
      archievedCard: false,
      projectId: this.$route.params.id,
      projectName: "",
      projectCards: [],
      cardId: "",
      slug: this.$route.params.slug,
      taskid: 0,
      task_id: 0,
      cardid: 0,
      initial_taskid: 0,
      initial_cardid: 0,
      // TaskDetails: false,
      showtaskDetails: [],
      startCardIndex: 0,
      endCardIndex: 0,
      editingCardId: null,
      tempCardName: "",
      showCanvasDetails: false,
      flagList: [],
      memberList: [],
    };
  },
  created() {
    this.workspace(this.projectCards);
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("click", this.closeDropdown);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("click", this.closeDropdown);
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getOrgUser",
      "getSelectedOrganization",
      "getAccessToken",
    ]),
    dragOptions() {
      return {
        animation: 150,
        group: "task",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    activeCards() {
      return this.cards.filter((card) => card.status !== false);
    },
  },
  mounted() {
    this.workspace();
    this.getFlags();
    this.formatDate();
    // this.teamMemberList();
  },
  methods: {
    formatDate() {
      const now = new Date();
      const timeUntilMidnight =
        new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) - now;
      setTimeout(() => {
        this.todayDate = new Date();
        this.formatDate();
      }, timeUntilMidnight);
    },
    toggleArchievedCard() {
      this.archievedCard = !this.archievedCard;
    },
    toggleArchieved() {
      this.archievedTask = !this.archievedTask;
    },
    toggleArchievedDropdown() {
      this.showDropDown = !this.showDropDown;
    },
    closeDropdown(event) {
      if (
        this.$refs.dropdownContainer &&
        !this.$refs.dropdownContainer.contains(event.target)
      ) {
        this.showDropDown = false;
      }
    },
    handleArchivedOption() {
      console.log("Archived Tasks option clicked");
      this.showDropDown = false;
    },

    toggleDropdown(cardId) {
      if (this.selectedProjectId === cardId) {
        this.dropdownOpen = !this.dropdownOpen;
      } else {
        this.selectedProjectId = cardId;
        this.dropdownOpen = true;
      }
    },
    handleOptionClick() {
      this.dropdownOpen = false;
    },
    handleClickOutside(event) {
      const dropdown = this.$refs.dropdownMenu;
      const buttons = this.$refs.optionsButton;

      const isClickInsideDropdown = dropdown && dropdown.contains(event.target);
      const isClickOnAnyButton = Array.isArray(buttons)
        ? buttons.some((button) => button.contains(event.target))
        : buttons?.contains(event.target);

      if (isClickInsideDropdown || isClickOnAnyButton) {
        return;
      }

      this.dropdownOpen = false;
    },

    async archiveCard(cardId) {
      try {
        await axios.patch(
          `${process.env.VUE_APP_API}/project-card/update-destroy/${cardId}/`,
          {
            status: false,
          },
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
      } catch (error) {
        console.error("Error archiving card:", error);
      }

      this.workspace();
    },
    async toggleCompletionStatus(tid, el) {
      console.log("task status:", el.target.checked);
      try {
        const response = await axios.patch(
          `${process.env.VUE_APP_API}/task/update-destroy/${tid}/`,
          {
            is_completed: el.target.checked,
          },
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        if (response.status === 200) {
          this.$emit("updateTask");
          this.workspace();
          console.log("Task status updated:", response.data);
        } else {
          console.error("Failed", response.data.message);
        }
      } catch (error) {
        console.error("Error updating:", error);
        this.errorMessage = "Failed";
      }
    },
    async teamMemberList() {
      console.log("project id in workspace", this.projectId);
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/team-member/list/?project__id=${this.projectId}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        if (response.status === 200) {
          this.memberList = response.data.data;
        } else {
          this.memberList = [];
          console.error(
            "Failed to retrieve team members:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },
    async getFlags() {
      await axios
        .get(`${process.env.VUE_APP_API}/flag/list/`, {
          params: {
            organization_accesskey_id:
              this.getSelectedOrganization.organization_id,
          },
          headers: {
            Authorization: `Bearer ${this.getAccessToken}`,
          },
        })
        .then((resp) => {
          if (resp.status === 200) {
            this.flagList = resp.data.data;
            console.log("flag list ----:", resp.data.data);
          } else {
            console.error("Error while adding task:", resp.data);
          }
        });
    },

    getSecureUrl(url) {
      if (url.startsWith("http:")) {
        return url.replace("http:", "https:");
      }
      return url;
    },
    toggleCanvasdetails() {
      this.showCanvasDetails = false;
    },
    startEditingCard(card) {
      this.editingCardId = card.id;
      this.tempCardName = card.name;
    },
    cancelEditCard() {
      const card = this.projectCards.find((c) => c.id === this.editingCardId);
      if (card) {
        card.name = this.tempCardName;
      }
      this.editingCardId = null;
    },
    async saveCardName(card) {
      try {
        if (card.name !== this.tempCardName) {
          await axios.patch(
            `${process.env.VUE_APP_API}/project-card/update-destroy/${card.id}/`,
            {
              name: card.name,
            },
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
              },
            }
          );
          this.editingCardId = null;
          this.tempCardName = "";
          this.workspace();
        } else {
          console.log("Name Not Changed !!");
        }
      } catch (error) {
        console.error("Error updating card name:", error);
      }
    },
    updateTask() {
      this.workspace();
    },
    ShowTaskDetails(task, taskid) {
      this.showtaskDetails = task;
      this.task_id = taskid;
      // this.toggleTaskDetails();
      this.showCanvasDetails = true;
    },

    cardSubmit() {
      this.workspace();
    },

    // draggable code starts
    taskDragOver(tid, index) {
      this.taskid = tid;
      this.endCardIndex = index;
      // console.log(
      //   "initial task index",
      //   this.startCardIndex,
      //   "end task index",
      //   this.endCardIndex
      // );
    },
    taskDragStart(i_id, index) {
      // console.log("INITIAL TASK ID ----", i_id, "card Index", index);
      this.initial_taskid = i_id;
      this.startCardIndex = index;
    },
    async cardDragStart(i_cid) {
      console.log("INITIAL CARD ID ---", i_cid);
      this.initial_cardid = i_cid;
    },

    cardDragOver(cardId) {
      this.cardid = cardId;
      console.log("Dragging over card ID:", cardId);
    },

    async updateTaskOrder() {
      try {
        const initialCard = this.projectCards.find(
          (c) => c.id === this.initial_cardid
        );
        const targetCard = this.projectCards.find((c) => c.id === this.cardid);

        if (!initialCard || !targetCard) return;

        initialCard.project_card_task.forEach((task, index) => {
          task.order = index;
        });

        targetCard.project_card_task.forEach((task, index) => {
          task.order = index;
        });

        const updatePromises = [
          ...initialCard.project_card_task.map((task) =>
            axios.patch(
              `${process.env.VUE_APP_API}/task/update-destroy/${task.id}/`,
              {
                order: task.order,
                project_card: this.initial_cardid,
              },
              {
                params: {
                  organization_accesskey_id:
                    this.getSelectedOrganization.organization_id,
                },
                headers: {
                  Authorization: `Bearer ${this.getAccessToken}`,
                },
              }
            )
          ),
          ...targetCard.project_card_task.map((task) =>
            axios.patch(
              `${process.env.VUE_APP_API}/task/update-destroy/${task.id}/`,
              {
                order: task.order,
                project_card: this.cardid,
              },
              {
                params: {
                  organization_accesskey_id:
                    this.getSelectedOrganization.organization_id,
                },
                headers: {
                  Authorization: `Bearer ${this.getAccessToken}`,
                },
              }
            )
          ),
        ];

        await Promise.all(updatePromises);
        this.workspace();
        console.log("Task order updated successfully between cards.");
      } catch (error) {
        console.error("Error updating task order:", error);
      }
    },

    async workspace() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/project/list/?slug=${this.slug}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        if (response.data.status === 200) {
          this.projectCards = response.data.data[0].project_card;
          this.projectId = response.data.data[0].id;
          this.projectName = response.data.data[0].name;
          this.teamMemberList();
        } else {
          console.error("API response error", response);
        }
      } catch (error) {
        console.error("Error fetching workspace:", error);
      }
    },

    formatDateTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month}`;
    },

    toggleCardForm() {
      this.toggleCard = !this.toggleCard;
      if (this.toggleFlag == true) {
        this.toggleFlag = false;
      }
    },
    // toggleFlagForm() {
    //   this.toggleFlag = !this.toggleFlag;
    //   if (this.toggleCard == true) {
    //     this.toggleCard = false;
    //   }
    // },
    toggleTaskForm(cardId) {
      this.cardId = cardId;
      this.taskForm = !this.taskForm;
    },

    handleFormSubmit(newTask) {
      const card = this.projectCards.find((card) => card.id === this.cardId);
      if (card) {
        card.project_card_task.push(newTask);
      }
      this.workspace();
      this.taskForm = false;
    },
  },
};
</script>
<style scoped>
.taskcheckbox:checked {
  background-image: url("@/assets/checked.svg");
  background-position: center;
  background-size: 18px;
}

.ghost {
  border: 1px dashed gray;
  opacity: 0.5;
  background: #ffffff;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.flip-list {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.drag-area {
  min-height: 50px;
  padding: 10px 0;
}
</style>
