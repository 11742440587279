<template>
  <div class="flex gap-4 mx-5">
    <LeftNav class="mt-[20px] mr-[10px]" />
    <div class="min-h-[calc(100vh-40px)] h-full mt-[20px] w-full">
      <div class="flex flex-col gap-2 w-full">
        <TopNav />
        <div class="w-full h-[90vh] pb-3 overflow-auto">
          <div class="py-3">
            <div class="bg-white px-6 pb-6 rounded-lg shadow-lg">
              <div class="text-lg py-3">Designations</div>
              <div class="">
                <table class="min-w-full border-collapse">
                  <!-- Table Header -->
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        Designation
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        Category
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr v-if="roleList.length === 0" class="text-center">
                      <td colspan="3" class="py-3">No roles available</td>
                    </tr>
                    <tr
                      v-for="role in roleList"
                      :key="role.id"
                      class="hover:bg-gray-200 text-sm"
                    >
                      <td
                        class="px-3 py-1 text-wrap w-56 text-start border border-gray-300"
                      >
                        {{
                          role.role_category ? role.role_category.name : "N/A"
                        }}
                      </td>
                      <td
                        class="px-3 py-1 text-wrap w-56 text-start border border-gray-300"
                      >
                        {{ role.name || "N/A" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-if="roleList.length === 0"
                  class="bg-white flex w-full py-5 justify-center"
                >
                  No Roles Available !!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import LeftNav from "@/components/LeftNavigation/LeftNav.vue";
import TopNav from "@/components/TopNav.vue";

export default {
  name: "MyTasks",
  components: {
    LeftNav,
    TopNav,
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getOrgUser",
      "getSelectedOrganization",
      "getAccessToken",
    ]),
  },
  data() {
    return {
      roleList: [],
    };
  },
  created() {
    this.getUserRole(this.getSelectedOrganization.organization_id);
  },
  mounted() {},
  methods: {
    async getUserRole(organization_id) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/user-role/list/?role_category__organization_id=${organization_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        if (response.data && Array.isArray(response.data.data)) {
          this.roleList = response.data.data;
          console.log("Role List:", this.roleList);
        } else {
          console.error("Unexpected response structure:", response.data);
          this.roleList = [];
        }
      } catch (error) {
        console.error("Error fetching user roles:", error);
      }
    },
  },
};
</script>
