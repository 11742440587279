import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import SignupView from "@/views/SignupView.vue";
import workSpace from "@/views/WorkSpace.vue";
import WorkspaceDashboard from "../views/Organization/DashboardView.vue";
import HomeView from "@/views/HomeView.vue";
import DashboardView from "@/views/DashboardView.vue";
import DesignationView from "@/views/Organization/DesignationView.vue";
import OrganizationUserList from "@/views/Organization/OrganizationUserList.vue";
const routes = [
  {
    path: "/workspace/:slug",
    name: "workspace",
    props: true,
    component: workSpace,
    meta: { requiresAuth: true },
  },
  {
    path: "/signup",
    name: "signupview",
    component: SignupView,
  },
  {
    path: "/workspace/dashboard",
    name: "WorkspaceDashboard",
    component: WorkspaceDashboard,
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/forgotpassword/:id/:token",
    name: "forgotpassword",
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/emailverification",
    name: "emailverification",
    component: () => import("../views/EmailVerification.vue"),
  },
  {
    path: "/home-view",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/dashboard",
    name: "DashBoard",
    component: DashboardView,
  },
  {
    path: "/profilepage",
    name: "profilepage",
    component: () => import("../views/ProfilePage.vue"),
  },
  {
    path: "/organizationinvite/:token",
    name: "organizationinvite",
    component: () => import("../views/OrganizationInvite.vue"),
  },
  {
    path: "/project-dashboard/:slug",
    name: "ProjectDashboard",
    component: () => import("../views/Organization/ProjectDashboard.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/mytasks",
    name: "MyTasks",
    component: () => import("../views/MyTasks.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/testingview",
    name: "testingview",
    component: () => import("../views/TestingView.vue"),
  },
  {
    path: "/designation",
    name: "designation",
    component: DesignationView,
  },
  {
    path: "/organizationuserlist",
    name: "organizationuserlist",
    component: OrganizationUserList,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
