<template>
  <div
    v-if="preview"
    class="fixed z-[999] top-0 left-0 flex justify-center items-center right-0 bottom-0 min-h-screen h-full overflow-auto bg-black bg-opacity-70"
  >
    <div
      class="bg-white p-10 max-h-[calc(100vh-30px)] overflow-auto relative rounded-md"
    >
      <svg
        @click="closePopup"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="bg-red-500 h-7 text-white absolute right-3 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
      <div class="w-auto h-auto p-5 rounded-lg overflow-auto mx-auto">
        <img :src="getSecureUrl(prev_image)" class="h-full" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preview: Boolean,
    prev_image: String,
  },
  methods: {
    closePopup() {
      this.$emit("closeFileView");
    },
    getSecureUrl(url) {
      if (url.startsWith("http:")) {
        return url.replace("http:", "https:");
      }
      return url;
    },
  },
};
</script>

<style></style>
