<template>
  <div
    class="p-3 bg-white rounded-lg border-b md:flex md:flex-col h-auto relative"
  >
    <div
      v-if="isAuthenticated"
      @click="toggleDropdown"
      ref="dropdown"
      class="flex flex-col md:flex-row justify-end items-start md:items-center cursor-pointer"
    >
      <span class="text-sm md:text-base flex gap-2 items-center px-4">
        <img
          v-if="this.getUser.profile_image"
          :src="`https` + this.getUser.profile_image.slice(4)"
          class="rounded-full h-10 w-10 object-cover border-2 border-gray-200"
          alt="User Profile Image"
        />
        <img
          v-else
          :src="div"
          class="rounded-full h-10 w-10 object-cover border-2 border-gray-200"
        />
        {{ this.getUser.display_name ? this.getUser.display_name : "Nil" }}
      </span>
    </div>
    <div
      v-if="isOpen"
      class="bg-white text-black border flex flex-col right-10 top-[80px] w-40 rounded-lg gap-y-2 p-2 fixed z-40"
      x-show="isOpen"
    >
      <router-link to="/profilepage">
        <button class="w-full hover:bg-[#76bdeb] rounded-md py-1">
          Profile
        </button>
      </router-link>
      <button @click="logOut" class="hover:bg-[#76bdeb] rounded-md py-1">
        Logout
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import div from "@/assets/user6.jpg";

export default {
  name: "TopNav",
  props: {
    User: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: false,
      div,
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getOrganization",
      "isAuthenticated",
      "getProfileImage",
    ]),
  },

  created() {
    document.addEventListener("click", this.dropdown);
  },
  unmounted() {
    document.removeEventListener("click", this.dropdown);
  },
  methods: {
    // getSecureUrl(url) {
    //   return url.startsWith("http:") ? url.replace("http:", "https:") : url;
    // },
    ...mapActions(["logout"]),
    logOut() {
      this.logout();
      this.$router.push({ name: "login" });
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    dropdown(e) {
      let el = this.$refs.dropdown;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.isOpen = false;
      }
    },
  },
};
</script>
<style></style>
