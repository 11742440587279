<template>
  <section class="bg-white px-4 py-2">
    <div class="flex items-center space-x-4">
      <span
        class="bg-blue-500 rounded-full h-8 w-8 flex items-center justify-center text-white font-bold"
        >C</span
      >
      <h1 class="text-lg">Chadura Tech - New Website</h1>
    </div>
    <div class="flex items-center justify-between space-x-8">
      <div class="flex space-x-10">
        <button
          v-for="tab in tabs"
          :key="tab"
          @click="currentTab = tab"
          :class="currentTab === tab ? 'border-b-2 border-indigo-500' : ''"
          class="py-2 text-sm focus:outline-none"
        >
          {{ tab }}
        </button>
      </div>
    </div>
  </section>
  <section>
    <div v-if="currentTab === 'Dashboard'">
      <div class="border-b flex justify-start p-3">
        <button
          class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-transform hover:bg-[#76bdeb] shadow-lg"
        >
          + Add Chart
        </button>
      </div>
      <div class="flex flex-wrap gap-4 m-4">
        <div
          class="border bg-white rounded space-y-2 p-2 hover:border-blue-500 w-[23%]"
        >
          <p>Overdue Task</p>
          <h1>{{ overdueCount }}</h1>
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </div>
        </div>
        <div
          class="border bg-white rounded space-y-2 p-2 hover:border-blue-500 w-[23%]"
        >
          <p>Incompleted Task</p>
          <h1>{{ incompleteCount }}</h1>
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </div>
        </div>
        <div
          class="border bg-white rounded space-y-2 p-2 hover:border-blue-500 w-[23%]"
        >
          <p>Completed Tasks</p>
          <h1>{{ completedCount }}</h1>
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </div>
        </div>
        <div
          class="border bg-white rounded space-y-2 p-2 hover:border-blue-500 w-[23%]"
        >
          <p>Total Tasks</p>
          <h1>{{ totalCount }}</h1>
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </div>
        </div>
      </div>

      <!-- New chart grid -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 m-10 gap-y-10 mx-20">
        <!--  -->
        <div class="bg-white rounded-lg chart-container w-3/4 h-[200px] p-10">
          <h2 class="font-semibold mb-2">Pending - Priority wise tasks</h2>
          <DoughnutChart
            :data="priorityTasksData"
            :options="priorityChartOptions"
          />
          <p class="text-gray-800 text-sm mt-4">2 Filters</p>
        </div>
        <!-- Pending tasks by Section -->
        <div class="bg-white rounded-lg chart-container w-3/4 h-[200px] p-10">
          <h2 class="font-semibold mb-2">Pending tasks by Section</h2>
          <DoughnutChart :data="pendingTasksData" :options="doughnutOptions" />
          <p class="text-gray-800 text-sm mt-4">2 Filters</p>
        </div>
        <!-- Total tasks by assignee -->
        <div class="bg-white rounded-lg chart-container w-3/4 h-[200px] p-10">
          <h2 class="font-semibold mb-2">Total tasks by assignee</h2>
          <BarChart :data="assigneeTasksData" :options="barOptions" />
          <p class="text-gray-800 text-sm mt-4">2 Filters</p>
        </div>
        <!-- Total tasks by section -->
        <div class="bg-white rounded-lg chart-container w-3/4 h-[200px] p-10">
          <h2 class="font-semibold mb-2">Total tasks by section</h2>
          <BarChart :data="sectionTasksData" :options="barOptions" />
          <p class="text-gray-800 text-sm mt-4">2 Filters</p>
        </div>
        <!-- Developer - Last week completed tasks -->
        <div class="bg-white rounded-lg chart-container w-3/4 h-[200px] p-10">
          <h2 class="font-semibold mb-2">
            Developer - Last week completed tasks
          </h2>
          <BarChart :data="weeklyTasksData" :options="barOptions" />
          <p class="text-gray-800 text-sm mt-4">3 Filters</p>
        </div>
      </div>
    </div>
    <div v-if="currentTab === 'Overview'">
      <div class="w-full flex justify-center bg-white">
        <div class="p-6 min-h-screen">
          <!-- Project Description -->
          <div class="text-start">
            <h1 class="text-xl font-semibold">Project description</h1>
            <p class="">
              Stack upgradation and UI facelift for
              <a
                href="https://onlinechadura.com"
                class="text-blue-400 underline"
                >onlinechadura.com</a
              ><br />
              Moving from PHP / Drupal to Python / Django / VueJS framework.
            </p>
          </div>

          <!-- Project Roles -->
          <div class="mt-20">
            <h2 class="text-xl font-semibold text-start">Project roles</h2>
            <div
              class="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
            >
              <!-- Add Member Button -->
              <div class="flex justify-start items-center gap-x-4">
                <span
                  class="rounded-full border-2 border-dotted border-blue-500 w-10 h-10 p-1 flex items-center justify-center text-blue-500 font-semibold"
                  >+</span
                >
                <span class="font-medium">Add members</span>
              </div>
              <div
                class="flex items-center space-x-3 rounded-lg text-sm font-semibold"
                v-for="role in roles"
                :key="role.name"
              >
                <!-- Avatar -->
                <div
                  class="flex items-center justify-center w-10 h-10 rounded-full bg-indigo-500 text-white"
                >
                  {{ role.initials }}
                </div>
                <!-- Name and Role -->
                <div class="">
                  <p class="">{{ role.name }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Key Resources -->
          <div class="mt-10">
            <h2 class="text-xl font-semibold text-start">Key resources</h2>
            <div
              class="p-4 rounded-md mt-4 border border-blue-500 flex justify-center"
            >
              <div class="flex items-center justify-center space-x-4">
                <div class="w-[50%] flex justify-center">
                  <div class="border border-blue-500 p-10">IMG</div>
                </div>
                <div class="w-[50%] text-start space-y-2">
                  <p class="text-sm font-semibold">
                    Align your team around a shared vision with a project brief
                    and supporting resources.
                  </p>
                  <div class="flex gap-3">
                    <div class="flex gap-x-1">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                          />
                        </svg>
                      </div>
                      <p class="text-sm">Create project brief</p>
                    </div>
                    <div class="flex gap-x-1">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                          />
                        </svg>
                      </div>
                      <p class="text-sm">Add links & files</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Milestones -->
          <div class="mt-10">
            <h2
              class="text-xl font-semibold border-b border-blue-500 pb-2 text-left"
            >
              Milestones
            </h2>
            <div
              class="mt-2 text-gray-500 text-left border-b border-blue-500 flex gap-x-2 p-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3"
                />
              </svg>
              <p>Add milestone.....</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentTab === 'List'">
      <div class="p-6">
        <!-- Add Task Button -->
        <div class="flex justify-between items-center mb-4">
          <button
            class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-transform hover:bg-[#76bdeb] shadow-lg"
          >
            + Add Task
          </button>
          <div class="flex space-x-2">
            <button class="px-3 py-1 rounded-md flex gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                />
              </svg>

              Filter
            </button>
            <button class="px-3 py-1 rounded-md flex gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>

              Sort
            </button>
            <button class="px-3 py-1 rounded-md flex gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>

              Group
            </button>
            <button class="px-3 py-1 rounded-md flex gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                />
              </svg>

              Options
            </button>
          </div>
        </div>

        <div class="bg-white p-6 rounded-lg shadow-lg">
          <table class="min-w-full">
            <!-- Table Header -->
            <thead class="border border-blue-300">
              <tr class="">
                <th
                  scope="col"
                  class="px-3 tracking-wider py-3 text-xs font-bold uppercase text-start border-r border-blue-300"
                >
                  Task Name
                </th>
                <th
                  scope="col"
                  class="px-3 tracking-wider py-3 text-xs font-bold uppercase text-start border-r border-blue-300"
                >
                  Assignee
                </th>
                <th
                  scope="col"
                  class="px-3 tracking-wider py-3 text-xs font-bold uppercase text-start border-r border-blue-300"
                >
                  Due Date
                </th>
                <th
                  scope="col"
                  class="px-3 tracking-wider py-3 text-xs font-bold uppercase text-start border-r border-blue-300"
                >
                  Priority
                </th>
                <th
                  scope="col"
                  class="px-3 tracking-wider py-3 text-xs font-bold uppercase text-start border-r border-blue-300"
                >
                  Task Type
                </th>
                <th
                  scope="col"
                  class="px-3 tracking-wider py-3 text-xs font-bold uppercase text-start border-r border-blue-300"
                >
                  Category
                </th>
                <th
                  scope="col"
                  class="px-3 tracking-wider py-3 text-xs font-bold uppercase text-start border-r border-blue-300"
                >
                  Deployment
                </th>
              </tr>
            </thead>
            <!-- Table Body -->
            <tbody class="bg-white border border-blue-300">
              <tr
                v-for="(task, index) in tasks"
                :key="index"
                class="hover:bg-gray-50 border-b border-blue-300"
              >
                <td
                  class="px-3 py-1 whitespace-nowrap text-start border-r border-blue-300"
                >
                  {{ task.name }}
                </td>
                <td
                  class="px-3 py-1 whitespace-nowrap text-start border-r border-blue-300"
                >
                  {{ task.assignee }}
                </td>
                <td
                  class="px-3 py-1 text-red-500 whitespace-nowrap text-start border-r border-blue-300"
                >
                  {{ task.dueDate }}
                </td>
                <td
                  class="px-3 py-1 whitespace-nowrap text-start border-r border-blue-300"
                >
                  <span
                    class="px-3 py-1 rounded"
                    :class="getPriorityClass(task.priority)"
                  >
                    {{ task.priority }}
                  </span>
                </td>
                <td
                  class="px-3 py-1 whitespace-nowrap text-start border-r border-blue-300"
                >
                  <span
                    class="px-3 py-1 rounded"
                    :class="gettasktypeClass(task.type)"
                  >
                    {{ task.type }}
                  </span>
                </td>
                <td
                  class="px-3 py-1 whitespace-nowrap text-start border-r border-blue-300"
                >
                  <span
                    class="px-3 py-1 rounded"
                    :class="getcategorytypeClass(task.category)"
                  >
                    {{ task.category }}
                  </span>
                </td>
                <td class="px-3 py-1 whitespace-nowrap text-start">
                  <span
                    class="px-3 py-1 rounded"
                    :class="getdeploymenttypeClass(task.deployment)"
                  >
                    {{ task.deployment }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="currentTab === 'Files'">
      <div class="p-6">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
          <div
            v-for="image in images"
            :key="image.id"
            class="bg-white border rounded-lg p-4"
          >
            <img
              :src="image.src"
              :alt="image.title"
              class="w-full h-48 object-cover rounded-md mb-3"
            />
            <h3 class="text-sm font-semibold text-gray-800">
              {{ image.title }}
            </h3>
            <p class="text-xs text-gray-800">{{ image.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { defineComponent } from "vue";
import { Doughnut as DoughnutChart, Bar as BarChart } from "vue-chartjs";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export default defineComponent({
  name: "DashboardView",
  components: {
    DoughnutChart,
    BarChart,
  },
  data() {
    return {
      tabs: ["Dashboard", "Overview", "List", "Files"],
      currentTab: "Dashboard",
      flagTasksData: {
        labels: [],
        datasets: [],
      },
      overdueCount: 0,
      incompleteCount: 0,
      completedCount: 0,
      totalCount: 0,
      images: [
        {
          id: 1,
          src: "/path/to/image2.png",
          title: "PMS Issues",
          description: "Create Dashboard",
        },
        {
          id: 2,
          src: "/path/to/image2.png",
          title: "Atcharya Website Performance Issues",
          description: "Kumaran",
        },
        {
          id: 3,
          src: "/path/to/image3.png",
          title: "PMS API Issues",
          description: "Mahendran",
        },
      ],
      tasks: [
        {
          name: "Chadura-PMS",
          assignee: "Mahendran...",
          dueDate: "Yesterday",
          priority: "Medium",
          type: "Maintenance",
          category: "Functionality",
          deployment: "Production",
        },
        {
          name: "Atcharya tulsi",
          assignee: "Anbu...",
          dueDate: "Yesterday",
          priority: "High",
          type: "Performance",
          category: "Data issue",
          deployment: "Development",
        },
      ],
      roles: [
        { initials: "De", name: "Deepa (Project owner)" },
        { initials: "Vg", name: "Vijithra" },
        { initials: "Kt", name: "Keerthi (Django Developer)" },
        { initials: "Md", name: "Madhumitha (Developer)" },
        { initials: "Kv", name: "Kaviya (Developer)" },
        { initials: "HS", name: "Harsha(Developer)" },
      ],
      priorityTasksData: {
        labels: ["High", "Medium", "Critical"],
        datasets: [
          {
            data: [7, 4, 1],
            backgroundColor: ["#FFA500", "#FFD700", "#FF0000"],
            borderColor: "transparent",
          },
        ],
      },
      priorityChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: "70%",
        plugins: {
          legend: {
            position: "right",
            labels: {
              color: "black",
              usePointStyle: true,
              font: {
                size: 14,
              },
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.parsed !== undefined) {
                  label += context.parsed;
                }
                return label;
              },
            },
          },
        },
      },
      pendingTasksData: {
        labels: [
          "Backlog",
          "QC (Testing)",
          "In Progress",
          "Ready for Deployment",
        ],
        datasets: [
          {
            data: [9, 3, 1, 1],
            backgroundColor: ["#818cf8", "#a78bfa", "#60a5fa", "#34d399"],
          },
        ],
      },
      assigneeTasksData: {
        labels: ["RR", "J", "B", "A", "Ch", "W"],
        datasets: [
          {
            data: [4, 3, 3, 2, 1, 1],
            backgroundColor: "#818cf8",
          },
        ],
      },
      sectionTasksData: {
        labels: ["Backlog", "In Prog", "QC Test", "Ready for"],
        datasets: [
          {
            data: [9, 1, 3, 1],
            backgroundColor: "#818cf8",
          },
        ],
      },
      weeklyTasksData: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri"],
        datasets: [
          {
            data: [0, 0, 0, 1, 0],
            backgroundColor: "#818cf8",
          },
        ],
      },
      doughnutOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "right",
            labels: {
              color: "black", // Legend text color
            },
          },
          tooltip: {
            bodyColor: "black", // Tooltip text color
          },
        },
        cutout: "70%",
      },
      barOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "right",
            labels: {
              color: "black",
              font: {
                size: 14,
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: "black",
              stepSize: 1,
            },
            grid: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
          x: {
            ticks: {
              color: "black",
            },
            grid: {
              display: false,
            },
          },
        },
      },
    };
  },
  created() {
    this.fetchdueCount(1);
    this.fetchFlagTaskCount(5);
  },
  methods: {
    fetchFlagTaskCount(project_id) {
      this.loading = true;
      this.errorMessage = "";
      axios
        .get(
          `${process.env.VUE_APP_API}/flag/project/count/?flag_task_project_cardproject_id=${project_id}`
        )
        .then((response) => {
          const { data, status } = response;

          if (status === 200 && data) {
            const flagData = data.flags;

            // Process the flag data for charts
            let labels = [];
            let completedCounts = [];
            let pendingCounts = [];
            let overdueCounts = [];
            let overallCounts = [];

            flagData.forEach((flag) => {
              labels.push(flag.name);
              completedCounts.push(flag.completed_count);
              pendingCounts.push(flag.pending_count);
              overdueCounts.push(flag.over_due);
              overallCounts.push(flag.overall_task_count);
            });

            // Update your chart datasets
            this.flagTasksData = {
              labels,
              datasets: [
                {
                  label: "Completed Tasks",
                  data: completedCounts,
                  backgroundColor: "#34d399",
                },
                {
                  label: "Pending Tasks",
                  data: pendingCounts,
                  backgroundColor: "#f59e0b",
                },
                {
                  label: "Overdue Tasks",
                  data: overdueCounts,
                  backgroundColor: "#ef4444",
                },
                {
                  label: "Overall Tasks",
                  data: overallCounts,
                  backgroundColor: "#3b82f6",
                },
              ],
            };
          } else {
            this.errorMessage = "Unexpected response structure or status";
            console.error(this.errorMessage);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage = `Response data: ${error.response.data}`;
          } else {
            this.errorMessage = `Error: ${error.message}`;
          }
          console.error(this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchdueCount(project_id) {
      this.loading = true;
      this.errorMessage = "";

      axios
        .get(
          `${process.env.VUE_APP_API}/project/task/list/?project__id=${project_id}`
        )
        .then((response) => {
          const { data, status } = response;

          if (status === 200 && data) {
            let totalOverdue = 0;
            let totalIncomplete = 0;
            let totalCompleted = 0;
            let totalCount = 0;

            if (data.project_cards && data.project_cards.length > 0) {
              data.project_cards.forEach((card) => {
                const { task_details } = card;

                if (task_details) {
                  totalOverdue += task_details.over_due || 0;
                  totalIncomplete += task_details.pending_count || 0;
                  totalCompleted += task_details.completed_count || 0;
                  totalCount += task_details.total_count || 0;
                }
              });
            } else {
              console.warn("No project cards found in the response data");
            }

            // Update the counts
            this.overdueCount = totalOverdue;
            this.incompleteCount = totalIncomplete;
            this.completedCount = totalCompleted;
            this.totalCount = totalCount;

            console.log(
              "Task counts fetched successfully for project ID:",
              project_id,
              {
                overdueCount: this.overdueCount,
                incompleteCount: this.incompleteCount,
                completedCount: this.completedCount,
                totalCount: this.totalCount,
              }
            );
          } else {
            this.errorMessage = "Unexpected response structure or status";
            console.error(this.errorMessage);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage = `Response data: ${error.response.data}`;
          } else {
            this.errorMessage = `Error: ${error.message}`;
          }
          console.error(this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // Consolidated getClass function
    getClass(type, value) {
      const classes = {
        deploymenttype: {
          Production: "bg-green-500 text-white text-sm",
          Development: "bg-orange-500 text-white text-sm",
          default: "bg-blue-500 text-white text-sm",
        },
        categorytype: {
          Functionality: "bg-orange-500 text-white text-sm",
          "Data issue": "bg-red-500 text-white text-sm",
          default: "bg-green-500 text-white text-sm",
        },
        priority: {
          High: "bg-red-500 text-white text-sm",
          Medium: "bg-yellow-500 text-white text-sm",
          Critical: "bg-red-700 text-white text-sm",
          default: "bg-gray-500 text-white text-sm",
        },
        tasktype: {
          Maintenance: "bg-orange-500 text-white text-sm",
          Bug: "bg-red-500 text-white text-sm",
          Performance: "bg-green-500 text-white text-sm",
          default: "bg-blue-500 text-white text-sm",
        },
      };

      return classes[type]?.[value] || classes[type]?.default;
    },
  },
});
</script>

<style scoped>
.chart-container {
  position: relative;
}
</style>
