<template>
  <div
    v-if="taskForm"
    class="bg-black z-[999] fixed top-0 left-0 flex justify-center items-center right-0 bottom-0 bg-opacity-10"
  >
    <form
      @submit.prevent="saveChanges"
      class="card text-start bg-white flex flex-col gap-y-4 w-auto relative md:w-[40rem] p-5 rounded-lg"
    >
      <div
        class="flex items-center justify-between bg-blue-600 rounded-lg relative text-white py-4 px-4"
      >
        <h1 class="text-center text-lg font-semibold">Add Task</h1>

        <svg
          @click="closeTaskForm"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="bg-red-500 h-8 text-white absolute right-3 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div class="mb-2">
        <label for="taskname">Name <b class="text-red-500">*</b></label>
        <input
          required
          type="text"
          v-model="addTaskForm.name"
          placeholder="Enter task name"
          class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
        />
      </div>
      <div class="flex items-center gap-x-5 justify-between">
        <div class="mb-2 w-full">
          <div for="taskname">Start Date</div>
          <input
            type="date"
            v-model="addTaskForm.start_date"
            class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
          />
        </div>
        <div class="mb-2 w-full">
          <div for="taskname">End Date</div>
          <input
            type="date"
            v-model="addTaskForm.end_date"
            class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
          />
        </div>
        <div class="mb-2 w-full">
          <div>Priority <b class="text-red-500">*</b></div>
          <select
            required
            v-model="addTaskForm.priority"
            class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 w-full py-[9.6px] px-2 text-start focus:ring-2 focus:ring-[#76bdeb]"
          >
            <option value="" selected>Select</option>
            <option v-for="flag in flagList" :key="flag.id" :value="flag.id">
              {{ flag.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex items-center gap-x-5 justify-between">
        <div class="mb-2 w-1/2">
          <label for="taskname">Image</label>
          <input
            type="file"
            @change="fileName"
            class="file-upload text-slate-700 bg-slate-100 rounded-md border border-gray-300 w-full text-start focus:ring-2 focus:ring-[#76bdeb]"
          />
        </div>
        <div class="mb-2 w-1/2">
          <div>Assign to</div>
          <select
            v-model="addTaskForm.member_id"
            class="w-full text-slate-700 text-sm bg-slate-100 rounded-md border border-gray-300 py-2.5 text-start px-2"
          >
            <option value="" selected default>Assign to</option>
            <option
              v-for="member in memberList"
              :key="member.id"
              :value="member.id"
            >
              {{ member.organization_user.user.display_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-2">
        <label for="taskdescription"
          >Description <b class="text-red-500">*</b></label
        >
        <textarea
          required
          v-model="addTaskForm.description"
          rows="3"
          class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
          placeholder="Describe your task"
        ></textarea>
      </div>

      <button
        class="bg-soft-primary w-36 justify-center mx-auto border border-primary text-gray-900 py-2 rounded-lg text-sm transition-all hover:bg-primary shadow-lg"
      >
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    showTaskForm: {
      type: Boolean,
    },
    card: {
      type: Object,
    },
    flagList: Array,
    getOrgUser: Object,
    memberList: Object,
  },
  data() {
    return {
      taskForm: this.showTaskForm,
      addTaskForm: {
        name: "",
        description: "",
        taskimage: "",
        start_date: "",
        end_date: "",
        priority: "",
        member_id: "",
      },
      taskImage: "",
    };
  },
  computed: {
    ...mapGetters(["getSelectedOrganization", "getAccessToken"]),
  },
  methods: {
    fileName(event) {
      console.log("fileName", event.target.files[0]);
      this.addTaskForm.taskimage = event.target.files[0];
      this.taskImage = event.target.files[0].name;
    },
    async saveChanges() {
      let formData = {
        name: this.addTaskForm.name,
        order: this.card.project_card_task.length,
        description: this.addTaskForm.description,
        project_card: this.card.id,
        flag: this.addTaskForm.priority,
        start_date: this.addTaskForm.start_date,
        end_date: this.addTaskForm.end_date,
        document: this.addTaskForm.taskimage,
        created_by: this.getOrgUser,
        assigned_to: this.addTaskForm.member_id,
        status: true,
      };
      await axios
        .post(
          `${process.env.VUE_APP_API}/task/create/`,
          formData,

          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            console.log("Task successfully added:", resp);
            this.$emit("formSubmit", resp.data.data);
          } else {
            console.error("Error while adding task:", resp.data.message);
          }
        });
      this.closeTaskForm();
    },

    closeTaskForm() {
      this.$emit("close-form");
    },
  },
};
</script>
