<template>
  <div v-if="toggleCard" class="w-auto">
    <form
      @submit.prevent="addCard"
      class="gap-3 bg-gray-50 shadow-lg border p-5 flex flex-col rounded-md h-auto"
    >
      <svg
        @click="toggleCardForm"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="h-7 bg-red-500 text-white p-1 rounded-sm cursor-pointer absolute right-4 font-bold"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
      <label for="name" class="text-black text-start text-lg"
        >Name <b class="text-red-500">*</b></label
      >
      <input
        type="text"
        v-model="name"
        class="py-1 px-2 bg-gray-100 border rounded-sm"
        placeholder="Enter card name"
      />

      <button
        type="submit"
        class="bg-soft-primary border w-20 ml-auto border-[#76bdeb] text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-all shadow-lg"
      >
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "WorkSpaceAddCard",
  props: {
    toggleCard: Boolean,
    toggleCardForm: Function,
    projectId: Number,
    projectCards: Object,
  },
  data() {
    return {
      name: "",
    };
  },
  computed: {
    ...mapGetters(["getSelectedOrganization", "getAccessToken"]),
  },

  methods: {
    async addCard() {
      const formData = {
        project: this.projectId,
        name: this.name,
        order: this.projectCards.length + 1,
      };

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API}/project-card/create/`,
          formData,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        console.log("project card created ::", response);
        if (response.status === 200) {
          this.$emit("cardSubmit");
          this.toggleCardForm();
        } else {
          console.error("Error adding card:", response.data.message);
        }
      } catch (error) {
        console.error("Error adding card:", error);
      }
    },
  },
};
</script>
