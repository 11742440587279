<template>
  <div class="flex gap-4 mx-5">
    <div
      v-if="archievedTask"
      class="bg-black z-[999] fixed top-0 left-0 flex justify-center items-center right-0 bottom-0 bg-opacity-10"
    >
      <div
        @click.stop
        class="fixed top-0 right-0 bg-slate-200 2xl:w-[300px] lg:w-[279px] md:w-[200px] h-full shadow-lg overflow-auto"
      >
        <div
          class="flex items-center justify-between bg-blue-600 rounded-lg relative text-white py-4 px-4"
        >
          <h1 class="text-center text-sm font-semibold">Archieved Task List</h1>

          <button
            @click="closeArchievedCanvas"
            class="bg-red-500 h-8 text-white absolute right-3 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <ul class="2xl:w-[300px] lg:w-[279px] md:w-[200px] overflow-auto p-3">
          <li
            v-for="task in tasks"
            :key="task.id"
            class="relative rounded-xl my-3 bg-white shadow-md hover:shadow-lg transition-shadow duration-200 border border-gray-200 overflow-hidden w-full"
          >
            <div class="py-4">
              <h2
                class="p-2 px-3 text-gray-700 text-sm capitalize text-start"
                style="line-height: 20px"
              >
                {{ task.name }}
              </h2>
              <hr />
              <img
                v-if="task.document"
                :src="getSecureUrl(task.document)"
                alt="task image"
                class="min-h-full max-h-48 mx-auto w-full object-top object-cover"
              />
              <p class="text-sm text-start text-gray-600 mt-2 truncate px-4">
                {{ task.description || "No description provided" }}
              </p>
              <div class="flex flex-col mt-3 gap-y-1 pb-3">
                <span class="px-3 flex justify-between items-center">
                  <span
                    v-if="task.end_date"
                    class="text-xs flex items-center gap-x-2"
                  >
                    <img src="@/assets/calendar.svg" class="w-4" alt="" />
                    <span>
                      {{ formatDateTime(task.end_date) }}
                    </span>
                  </span>
                  <span
                    v-if="task.flag?.name"
                    class="flex items-center bg-gray-100 px-2 py-1 ml-auto rounded-md"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-5 h-5"
                      viewBox="0 0 64 64"
                      :fill="task.flag?.colour_code || '#000000'"
                      :stroke="task.flag?.colour_code || '#000000'"
                      stroke-width="2.5"
                    >
                      <polyline points="16 32 48 32 36 20 48 8 16 8 16 56" />
                    </svg>
                    <span class="ml-2 text-xs">{{ task.flag?.name }}</span>
                  </span>
                </span>
              </div>

              <div class="font-semibold items-center flex justify-between px-3">
                <span class="flex gap-x-1">
                  <img src="@/assets/assignedto.svg" class="h-4" alt="" />
                  <span class="text-gray-600 capitalize text-xs">
                    {{
                      task.assigned_to && task.assigned_to.user?.display_name
                    }}
                  </span>
                </span>
                <div>
                  <button
                    @click="unArchiveTaskCard(task.id)"
                    class="w-full flex items-center gap-x-1 text-sm font-medium text-gray-700"
                  >
                    <svg
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12"
                        stroke="#1C274C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14Z"
                        stroke="#1C274C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M12 11L12 17M12 17L14.5 14.5M12 17L9.5 14.5"
                        stroke="#00000098"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="text-gray-800 mt-1 capitalize text-xs"
                      >UnArchieve</span
                    >
                  </button>
                </div>
              </div>
            </div>
            <div v-if="task.showSuccess" class="text-green-800 text-sm w-auto">
              {{ task.successMessage }}
            </div>

            <div v-if="task.showError" class="text-red-800 text-sm w-auto">
              {{ task.errorMessage }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "ArchievedTaskList",
  props: {
    showarchievedTask: {
      type: Boolean,
    },
    projectId: {
      type: String,
    },
    type: Array,
    default: () => [],
  },
  data() {
    return {
      archievedTask: this.showarchievedTask,
      tasks: [],
      taskId: [],
    };
  },
  created() {
    this.fetchTasks();
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getOrgUser",
      "getSelectedOrganization",
      "getAccessToken",
    ]),
  },

  methods: {
    async unArchiveTaskCard(taskId) {
      try {
        const response = await axios.patch(
          `${process.env.VUE_APP_API}/task/update-destroy/${taskId}/`,
          { status: true },
          {
            params: {
              project_id: this.projectId,
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        const task = this.tasks.find((task) => task.id === taskId);
        if (task) {
          task.successMessage =
            response.data.message || "Task successfully unarchived";
          task.showSuccess = true;
          setTimeout(() => (task.showSuccess = false), 3000);
        }

        await this.fetchTasks();
      } catch (error) {
        const task = this.tasks.find((task) => task.id === taskId);
        if (task) {
          task.errorMessage =
            error.response?.data?.message ||
            "Permission required to unarchive task.";
          task.showError = true;
          setTimeout(() => (task.showError = false), 3000);
        }
        console.error("Error:", error);
      }
    },
    getSecureUrl(url) {
      if (url.startsWith("http:")) {
        return url.replace("http:", "https:");
      }
      return url;
    },
    async fetchTasks() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/task/archive/list/?project_card__project_id=${this.projectId}`,
          {
            params: {
              project_id: this.projectId,
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        if (response.status == 200) {
          this.tasks = response.data?.data || [];
        } else {
          this.tasks = [];
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    },
    formatDateTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month}`;
    },
    closeArchievedCanvas() {
      this.$emit("close-form");
      this.$emit("refresh");
    },
  },
};
</script>
