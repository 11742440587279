<template>
  <div
    class="bg-white flex flex-col justify-center items-center overflow-y-auto min-h-screen"
  >
    <form
      class="relative flex flex-col gap-y-2 md:gap-y-3 w-[95%] md:w-[75%] max-w-[500px] rounded-xl shadow-xl border border-gray-200 p-6"
      @submit.prevent="signUp()"
    >
      <img
        @click="toHome"
        src="../assets/chadura-logo.png"
        alt="logo"
        class="mx-auto w-auto h-10 md:h-12"
      />

      <!-- <h1 class="text-xl md:text-3xl font-semibold text-center roboto-medium">
        Organization Sign up
      </h1> -->
      <h2 class="text-sm md:text-xl text-center roboto-regular">
        Welcome to Chadura PMS!
      </h2>

      <div
        class="flex items-center p-1 border rounded-lg gap-x-2 roboto-light px-2"
      >
        <img
          src="../assets/User.svg"
          alt="UserName"
          class="w-4 object-contain"
        />
        <input
          type="text"
          id="full_name"
          class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
          required
          v-model="fullname"
          placeholder="Full Name *"
        />
      </div>

      <div
        class="flex items-center p-1 border rounded-lg gap-x-2 roboto-light px-2"
      >
        <img src="../assets/call.png" alt="" class="w-4 object-contain" />
        <input
          type="phone"
          class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
          required
          v-model="mobile"
          placeholder="Mobile Number *"
        />
      </div>

      <div class="flex gap-2">
        <div
          class="flex items-center p-1 border rounded-lg gap-x-2 roboto-light px-2 w-full"
        >
          <img
            src="../assets/mail.svg"
            alt="Email"
            class="w-4 object-contain"
          />
          <input
            type="email"
            class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
            id="email"
            v-model="email"
            placeholder="Enter your Email *"
          />
        </div>
      </div>

      <div class="flex gap-2">
        <div
          class="flex items-center p-1 border rounded-lg gap-x-2 roboto-light px-2 w-full"
        >
          <img
            src="../assets/User.svg"
            alt="Organization"
            class="w-4 object-contain"
          />
          <input
            type="text"
            class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
            required
            v-model="org_name"
            placeholder="Organization Name *"
          />
        </div>
      </div>

      <div class="flex gap-2">
        <div class="flex items-center p-1 border rounded-lg gap-x-2 w-1/2 px-2">
          <img
            src="../assets/lock.svg"
            alt="Password"
            class="w-4 object-contain"
          />
          <input
            type="password"
            class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
            id="password"
            v-model="password"
            placeholder="Enter your Password *"
            minlength="8"
          />
        </div>

        <div class="flex items-center p-1 border rounded-lg gap-x-2 w-1/2 px-2">
          <img
            src="../assets/lock.svg"
            alt="Confirm Password"
            class="w-4 object-contain"
          />
          <input
            type="password"
            class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
            required
            v-model="confirmPassword"
            placeholder="Re-enter your Password *"
            minlength="8"
          />
        </div>
      </div>

      <p
        v-if="message.text"
        class="text-start capitalize"
        :class="message.status == 200 ? 'text-green-700' : 'text-red-700'"
      >
        {{ message.text }}
      </p>

      <button
        :disabled="loading"
        type="submit"
        class="w-full border h-[49px] rounded-lg font-bold bg-[#2A84EF] text-white roboto-light"
      >
        {{ loading ? "Loading..." : "Sign Up" }}
      </button>

      <div class="flex flex-col gap-y-4 roboto-light">
        <div class="flex items-center p-2">
          <span class="flex-grow border-b border-gray-300"></span>
          <p class="text-sm text-gray-500 mx-3">Already have an account?</p>
          <span class="flex-grow border-b border-gray-300"></span>
        </div>

        <router-link to="/">
          <button
            class="bg-black text-white rounded-md py-1 text-center roboto-light h-[49px] w-full"
          >
            Sign in here
          </button>
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SignupView",
  data() {
    return {
      fullname: "",
      email: "",
      password: "",
      mobile: "",
      confirmPassword: "",
      org_name: "",
      message: {
        text: "",
        status: "",
      },
      loading: false,
    };
  },
  methods: {
    async signUp() {
      // console.log(el.target.elements.full_name.value, "el++++++");
      this.loading = true;

      let data_json = {
        display_name: this.fullname,
        email: this.email,
        phone_number: this.mobile,
        organization: { name: this.org_name },
        password: this.password,
      };

      if (this.password !== this.confirmPassword) {
        this.message.text = "Passwords do not match!";
        this.message.status = 400;
        this.loading = false;
        return;
      }
      try {
        const apiResponse = await axios.post(
          `${process.env.VUE_APP_API}/api/user-organization-create/`,
          data_json
        );
        if (apiResponse.status === 200) {
          console.log("API data:", apiResponse.data);
        }
        this.message.status = 200;
        this.message.text = "Sign up successful!";
        console.log("message status", this.message);
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 2000);
      } catch (error) {
        console.error("Error during sign up:", error);
        this.message.text = "Error during sign up.";
        this.message.status = error.response ? error.response.status : 500;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
