<template>
  <div
    v-if="createCategory"
    class="fixed inset-0 z-[999] bg-black bg-opacity-10"
  >
    <div
      class="fixed top-0 right-0 bg-white w-full max-w-sm h-full shadow-lg transform transition-transform duration-300"
    >
      <form
        @submit.prevent="saveCategory"
        class="flex flex-col gap-y-4 relative h-full"
      >
        <div
          class="flex items-center justify-between bg-blue-600 text-white py-4 px-4 rounded-t-lg"
        >
          <h1 class="text-lg font-semibold">Add Category</h1>
          <svg
            @click="closeAddCategory"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="bg-red-500 h-8 text-white font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <div class="flex-grow overflow-y-auto px-4 py-2 space-y-4 text-start">
          <div>
            <label for="name" class="block text-gray-700 text-sm mb-2">
              Name <b class="text-red-500">*</b>
            </label>
            <input
              type="text"
              v-model="name"
              id="name"
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
              required
            />
          </div>
          <div>
            <label for="shortName" class="block text-gray-700 text-sm mb-2">
              Short Name <b class="text-red-500">*</b>
            </label>
            <input
              type="text"
              v-model="shortName"
              id="shortName"
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
              required
            />
          </div>

          <div>
            <label for="organization" class="block text-gray-700 text-sm mb-2">
              Organization
            </label>
            <select
              v-model="organization"
              id="organization"
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
              required
            >
              <option value="">Select Organization</option>
              <option
                v-for="org in organizationList"
                :key="org.id"
                :value="org.id"
              >
                {{ org.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="py-4 text-center">
          <button
            type="submit"
            class="bg-blue-600 text-white w-36 py-2 rounded-lg text-sm font-medium transition-all hover:bg-blue-700 shadow-lg"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: {
    showCategoryCreate: {
      type: Boolean,
    },
  },

  data() {
    return {
      createCategory: this.showCategoryCreate,
      name: "",
      shortName: "",
      organization: "",
      organizationList: [],
      successMessage: "",
      errorMessage: "",
      loading: false,
    };
  },

  created() {
    this.getOrganization();
  },

  computed: {
    ...mapGetters(["getSelectedOrganization", "getAccessToken"]),
  },

  methods: {
    async getOrganization() {
      try {
        this.loading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_API}/organization/list/`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        this.organizationList = response.data.data || [];
      } catch (error) {
        console.error("Error fetching organizations:", error);
      } finally {
        this.loading = false;
      }
    },

    async saveCategory() {
      try {
        this.loading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API}/role-category/create/`,
          {
            name: this.name,
            short_name: this.shortName,
            organization: this.organization,
          },
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        if (response.data.success) {
          this.successMessage =
            response.data.message || "Category created successfully.";
          this.closeAddCategory();
        } else {
          this.errorMessage =
            response.data.message || "Failed to create category.";
        }
      } catch (error) {
        this.errorMessage = "An error occurred while creating the category.";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    closeAddCategory() {
      this.resetForm();
      this.$emit("close-form");
    },

    resetForm() {
      this.name = "";
      this.shortName = "";
      this.organization = "";
      this.successMessage = "";
      this.errorMessage = "";
    },
  },
};
</script>
