<template>
  <div
    class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black z-10 bg-opacity-90"
  >
    <div class="w-[520px] h-auto bg-white rounded-lg p-4 relative">
      <button
        class="absolute top-2 right-4 text-3xl text-red-700"
        @click="$emit('closePopUp')"
      >
        <img src="@/assets/close.png" class="w-6 h-6 object-contain" alt="" />
      </button>
      <h1 class="text-3xl">Invite Members</h1>
      <p>Invite your team members into the organization</p>

      <form class="p-4 flex flex-col gap-4" @submit.prevent="inviteuser">
        <div class="flex flex-col gap-2 items-start">
          <label for="email_id"
            >Email Address <span class="text-red-600">*</span></label
          >
          <input
            type="email"
            id="email_id"
            v-model="emailaddress"
            class="border border-gray-300 h-[35px] p-1 rounded-md w-full"
            required
          />
        </div>

        <div v-if="message.text" class="text-center mb-2">
          <p
            :class="{
              'text-green-600': message.status === 200,
              'text-red-600': message.status !== 200,
            }"
          >
            {{ message.text }}
          </p>
        </div>

        <div class="flex justify-center">
          <button
            type="submit"
            class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-transform hover:bg-[#76bdeb] shadow-lg"
            :disabled="loading"
          >
            {{ loading ? "Sending..." : "Send" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "InviteMember",
  data() {
    return {
      emailaddress: "",
      loading: false,
      message: {
        text: "",
        status: "",
      },
      OrgId: "",
    };
  },
  computed: {
    ...mapGetters(["getSelectedOrganization"]),
  },

  methods: {
    async inviteuser() {
      let data_json = {
        email: this.emailaddress,
        organization: this.getSelectedOrganization.organization_id,
      };

      console.log("Sending invite data:", data_json);

      this.loading = true;

      try {
        const apiResponse = await axios.post(
          `${process.env.VUE_APP_API}/api/org-user-invite/`,
          data_json
        );

        console.log("API Response Status:", apiResponse.status);
        console.log("API Response Data:", apiResponse.data);

        if (apiResponse.status === 201) {
          this.message.text = "Mail sent successfully!";
          this.message.status = 200;
          this.emailaddress = "";

          setTimeout(() => {
            this.$emit("closePopUp");
            this.message.text = "";
            this.message.status = "";
          }, 2000);
        } else {
          this.message.text = "Failed to send mail.";
          this.message.status = apiResponse.status;
          setTimeout(() => {
            this.message.text = "";
            this.message.status = "";
          }, 4000);
        }
      } catch (error) {
        console.error("Error during invite:", error);
        this.message.text = "Error during invite.";
        this.message.status = error.response?.status || 500;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Add custom styles if necessary */
</style>
