<template>
  <div class="flex gap-4 mx-5">
    <LeftNav class="mt-[20px] mr-[10px]" />
    <div class="min-h-[calc(100vh-40px)] h-full mt-[20px] w-full">
      <div class="flex flex-col gap-2 w-full">
        <TopNav />
        <div class="w-full h-[90vh] pb-3 overflow-auto">
          <section class="p-3 flex justify-center w-full">
            <div class="w-full space-y-3">
              <div class="">
                <h4 class="text-blue-500">{{ currentDate }}</h4>
                <h1 class="text-3xl font-bold text-blue-500">
                  {{ greeting }},
                  {{
                    this.getUser.display_name
                      ? this.getUser.display_name
                      : "Nil"
                  }}
                </h1>
              </div>
              <div
                class="flex justify-evenly items-center bg-white rounded-full max-w-lg mx-auto py-2 gap-x-2 border border-blue-500"
              >
                <select v-model="selectedMonth" class="rounded-lg p-1 bg-white">
                  <option value="mymonth">My month</option>
                </select>
                <div
                  class="md:flex gap-x-1 items-center justify-center border-l border-blue-500 px-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 text-blue-500 hidden md:flex"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                  <p>
                    <b class="">{{ overdueCount }}</b> Task Overdue
                  </p>
                </div>
                <div
                  class="md:flex gap-x-3 items-center justify-center px-2 border-l border-blue-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 text-blue-500 hidden md:flex"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                    />
                  </svg>
                  <p>
                    <b>{{ collaboratorsCount }}</b> Collaborators
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="w-full">
              <div class="md:flex gap-x-5 w-[100%]">
                <!-- My Tasks Card -->
                <div class="border p-4 rounded-xl bg-white w-2/3">
                  <div class="flex gap-x-2 mb-2">
                    <h2 class="text-lg lg:text-xl font-bold">My tasks</h2>
                  </div>

                  <div class="flex space-x-2 md:space-x-10 mb-3 border-b">
                    <button
                      v-for="tab in tabs"
                      :key="tab"
                      @click="currentTab = tab"
                      :class="
                        currentTab === tab ? 'border-b-2 border-indigo-500' : ''
                      "
                      class="py-2 text-sm focus:outline-none md:font-bold"
                    >
                      {{ tab }}
                    </button>
                  </div>
                  <div class="h-[300px] overflow-auto">
                    <!-- Task Content -->
                    <div v-if="currentTab === 'Current'">
                      <div
                        class="flex justify-between py-1 items-center overflow-x-auto"
                      >
                        <table class="min-w-full">
                          <thead class="">
                            <tr>
                              <th
                                class="py-2 px-3 text-white text-left bg-slate-600 rounded-l-lg font-bold text-sm"
                              >
                                Task Name
                              </th>
                              <th
                                class="py-2 px-3 text-white text-left bg-slate-600 rounded-r-lg font-bold text-sm"
                              >
                                Due Date
                              </th>
                            </tr>
                          </thead>
                          <tbody class="text-start text-sm">
                            <template v-if="currentTasks.length > 0">
                              <tr
                                v-for="task in currentTasks"
                                :key="task.id"
                                class="border-b"
                              >
                                <td class="py-2">
                                  <p
                                    class="flex justify-start ml-2 text-start line-clamp-2"
                                  >
                                    {{ task.name }}
                                  </p>
                                </td>
                                <td class="py-2">
                                  <p>
                                    {{
                                      task.endDate
                                        ? formatDateTime(task.endDate)
                                        : "---"
                                    }}
                                  </p>
                                </td>
                              </tr>
                            </template>
                            <tr v-else>
                              <td>No Data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div v-if="currentTab === 'Upcoming'">
                      <div class="py-1">
                        <table class="min-w-full">
                          <thead class="">
                            <tr>
                              <th
                                class="py-2 px-3 text-white text-left bg-slate-600 rounded-l-lg font-bold text-sm"
                              >
                                Task Name
                              </th>
                              <th
                                class="py-2 px-3 text-white text-left bg-slate-600 rounded-r-lg font-bold text-sm"
                              >
                                Due Date
                              </th>
                            </tr>
                          </thead>
                          <tbody class="text-start text-sm">
                            <template v-if="upcomingTasks.length > 0">
                              <tr
                                v-for="task in upcomingTasks"
                                :key="task.id"
                                class="border-b"
                              >
                                <td class="py-2">
                                  <p
                                    class="flex justify-start ml-2 text-start line-clamp-2"
                                  >
                                    {{ task.name }}
                                  </p>
                                </td>
                                <td class="py-2">
                                  <p>
                                    {{
                                      task.endDate
                                        ? formatDateTime(task.endDate)
                                        : "---"
                                    }}
                                  </p>
                                </td>
                              </tr>
                            </template>
                            <tr v-else>
                              <td>No Data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div v-if="currentTab === 'Overdue'">
                      <div class="py-1">
                        <table class="min-w-full table-auto">
                          <thead class="">
                            <tr>
                              <th
                                class="py-2 px-3 text-white text-left bg-slate-600 rounded-l-lg font-bold text-sm"
                              >
                                Task Name
                              </th>
                              <th
                                class="py-2 px-3 text-white text-left bg-slate-600 rounded-r-lg font-bold text-sm"
                              >
                                Due Date
                              </th>
                            </tr>
                          </thead>
                          <tbody class="text-start text-sm">
                            <template v-if="overdueTasks.length > 0">
                              <tr
                                v-for="task in overdueTasks"
                                :key="task.id"
                                class="border-b"
                              >
                                <td class="py-2">
                                  <p
                                    class="flex justify-start ml-2 w-48 text-wrap text-start line-clamp-2"
                                  >
                                    {{ task.name }}
                                  </p>
                                </td>
                                <td class="py-2">
                                  <p>
                                    {{
                                      task.endDate
                                        ? formatDateTime(task.endDate)
                                        : "---"
                                    }}
                                  </p>
                                </td>
                              </tr>
                            </template>
                            <tr v-else>
                              <td>No Data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div v-if="currentTab === 'Completed'">
                      <div class="py-1 overflow-y-auto">
                        <table class="min-w-full">
                          <thead class="">
                            <tr>
                              <th
                                class="py-2 px-3 text-white text-left bg-slate-600 rounded-l-lg font-bold text-sm"
                              >
                                Task Name
                              </th>
                              <th
                                class="py-2 px-3 text-white text-left bg-slate-600 rounded-r-lg font-bold text-sm"
                              >
                                Due Date
                              </th>
                            </tr>
                          </thead>
                          <tbody class="text-start text-sm">
                            <template v-if="completedTasks.length > 0">
                              <tr
                                v-for="task in completedTasks"
                                :key="task.id"
                                class="border-b"
                              >
                                <td class="py-2">
                                  <span class="ml-2">{{ task.name }}</span>
                                </td>
                                <td class="py-2">
                                  <p class="">
                                    {{
                                      task.endDate
                                        ? formatDateTime(task.endDate)
                                        : "---"
                                    }}
                                  </p>
                                </td>
                              </tr>
                            </template>
                            <tr v-else>
                              <td>No Data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Projects Card -->
                <div class="bg-white p-4 w-1/3 rounded-xl border mt-10 md:mt-0">
                  <div class="flex justify-between items-center mb-4">
                    <h2 class="text-lg lg:text-xl font-bold">Projects</h2>
                    <!-- <button class="text-sm focus:outline-none text-blue-500">
                      Browse projects
                    </button> -->
                  </div>

                  <div class="h-[350px] overflow-y-auto">
                    <div class="grid grid-cols-1 gap-2">
                      <div
                        v-if="
                          this.getSelectedOrganization.user_level ==
                            'Level 1' ||
                          this.getSelectedOrganization.user_level ==
                            'Level 2' ||
                          this.getSelectedOrganization.user_level == 'Level 3'
                        "
                        class="flex items-center gap-x-3 cursor-pointer p-1"
                        @click="toggleProjectOffcanvas"
                      >
                        <span
                          class="border-2 border-dotted border-blue-500 text-blue-500 rounded-full w-8 h-8 flex items-center justify-center"
                          >+</span
                        >
                        <p>Create Project</p>
                      </div>

                      <div
                        v-for="project in projects"
                        :key="project.id"
                        class="p-1 rounded-lg flex items-center gap-4"
                      >
                        <div
                          class="w-8 h-8 rounded-full flex items-center justify-center bg-blue-500 text-white font-bold"
                        >
                          {{ project.name.charAt(0).toUpperCase() }}
                        </div>

                        <router-link
                          :to="{
                            name: 'ProjectDashboard',
                            params: { slug: project.slug },
                          }"
                        >
                          <button class="text-start">
                            <h3 class="text-sm">{{ project.name }}</h3>
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- People Section -->
              <div class="p-5 rounded-lg col-span-2 bg-white mb-5 mt-5">
                <h2 class="text-lg lg:text-xl text-start font-bold mb-4">
                  People
                </h2>
                <div
                  class="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-4"
                >
                  <div
                    v-if="
                      this.getSelectedOrganization.user_level == 'Level 1' ||
                      this.getSelectedOrganization.user_level == 'Level 2' ||
                      this.getSelectedOrganization.user_level == 'Level 3'
                    "
                    class="flex gap-x-3 px-3 items-center cursor-pointer"
                    @click="isPopUp = true"
                  >
                    <span
                      class="border-2 border-dotted border-blue-500 text-blue-500 rounded-full w-8 h-8 flex items-center justify-center"
                      >+</span
                    >
                    <p>Invite</p>
                  </div>
                  <div
                    v-for="person in people"
                    :key="person.id"
                    class="p-4 rounded-lg flex items-center justify-start gap-x-3"
                  >
                    <div
                      class="w-8 h-8 rounded-full flex items-center justify-center bg-blue-500 text-white font-bold"
                    >
                      {{ person.display_name.charAt(0).toUpperCase() }}
                    </div>
                    <p class="text-sm text-start">
                      {{ person.display_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Project Popup -->
            <div
              v-if="projectOffcanvss"
              class="bg-[#00000020] fixed left-0 top-0 right-0 h-full z-10 transition-all duration-300 ease-in-out"
            ></div>
            <div
              :class="[
                'fixed right-0 top-0 z-40 w-[21rem] rounded-tl-lg  h-full bg-white shadow-xl transition-transform duration-300 ease-in-out',
                isProjectOffcanvasOpen ? 'translate-x-0' : 'translate-x-full',
              ]"
            >
              <div
                class="flex items-center justify-between bg-blue-600 rounded-l-lg text-white py-4 px-4"
              >
                <h5 class="mb-0 font-semibold text-medium leading-normal">
                  Add New Project
                </h5>

                <svg
                  @click="toggleProjectOffcanvas"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div class="flex-grow overflow-y-auto p-4 text-[.9rem]">
                <form
                  ref="project_form"
                  @submit.prevent="projectPost"
                  class="flex flex-col gap-4"
                >
                  <div class="flex flex-col gap-1 items-start">
                    <label for="project_name">
                      Project Name <span class="text-red-500">*</span>
                    </label>
                    <input
                      id="project_name"
                      name="project_name"
                      type="text"
                      required
                      class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
                    />
                  </div>

                  <div class="flex flex-col gap-1 items-start">
                    <label for="workspacelist">
                      Select Workspace <span class="text-red-500">*</span>
                    </label>
                    <select
                      name="workspacelist"
                      id="workspacelist"
                      class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
                      v-model="selectedWorkspace"
                      @change="selectWorkspace(selectedWorkspace)"
                    >
                      <option value="">Select an option</option>
                      <option
                        v-for="workspace in workspace_details"
                        :key="workspace.id"
                        :value="workspace.id"
                      >
                        {{ workspace.name }}
                      </option>
                    </select>
                  </div>

                  <div class="flex flex-col gap-1 items-start">
                    <label for="bg_image">Background Image</label>
                    <input
                      id="bg_image"
                      name="bg_image"
                      type="file"
                      class="file-upload"
                    />
                  </div>

                  <div class="flex flex-col gap-1 items-start">
                    <label for="desc">Description</label>
                    <textarea
                      id="desc"
                      name="desc"
                      cols="30"
                      rows="4"
                      placeholder="Describe your workspace..."
                      class="rounded-md w-full text-gray-800 px-2 pt-0.5 border focus:outline-none focus:ring-2 focus:ring-blue-500"
                    ></textarea>
                  </div>

                  <div class="flex justify-center">
                    <button
                      type="submit"
                      class="bg-soft-primary border border-primary text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-all hover:bg-primary shadow-lg"
                    >
                      Add Project
                    </button>
                  </div>
                </form>
                <div
                  v-if="message"
                  :class="status === 200 ? 'text-green-500' : 'text-red-500'"
                >
                  {{ message }}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <InviteMemberVue v-if="isPopUp" @closePopUp="this.isPopUp = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import TopNav from "@/components/TopNav.vue";
import LeftNav from "@/components/LeftNavigation/LeftNav.vue";
import InviteMemberVue from "@/components/InviteMember.vue";

export default {
  data() {
    return {
      message: null,
      status: null,
      workspace_details: [],
      selectedWorkspace: "",
      isPopUp: false,
      isProjectOffcanvasOpen: false,
      overdueCount: "",
      collaboratorsCount: 0,
      selectedMonth: "mymonth",
      isModalOpen: false,
      newProjectName: "",
      tabs: ["Current", "Upcoming", "Overdue", "Completed"],
      currentTab: "Current",
      currentTasks: [],
      upcomingTasks: [],
      overdueTasks: [],
      completedTasks: [],
      projectId: [],
      projects: [],
      people: [],
      organizationId: "organization_id",
      greeting: "",
      currentTime: "",
      greetingClass: "",
      intervalId: null,
      user_member_id: "",
    };
  },
  components: {
    TopNav,
    LeftNav,
    InviteMemberVue,
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getSelectedOrganization",
      "getOrgUser",
      "getAccessToken",
    ]),
  },
  watch: {
    getSelectedOrganization: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.workspaceList();
          this.fetchProjects();
          this.fetchPeople();
          this.fetchTasks();
        }
      },
    },
    getOrgUser: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getmemberid();
          this.fetchTasks();
          this.taskoverdueCount();
        }
      },
    },
  },
  mounted() {
    this.fetchProjects();
    this.fetchTasks();
    this.taskoverdueCount();
    this.fetchPeople();
  },
  created() {
    this.getmemberid();
    this.workspaceList();
    this.startTimeUpdate();
  },
  methods: {
    fetchTasks() {
      axios
        .get(
          `${process.env.VUE_APP_API}/task-detail/list/?assigned_to__organization_user_id=${this.getOrgUser}`
        )
        .then((response) => {
          const data = response.data.data;

          if (data) {
            this.currentTasks = (data.tasks.current_tasks || []).map(
              (task) => ({
                ...task,
                startDate: task.start_date || null,
                endDate: task.end_date || null,
              })
            );

            this.overdueTasks = (data.tasks.overdue || []).map((task) => ({
              ...task,
              startDate: task.start_date || null,
              endDate: task.end_date || null,
            }));

            this.completedTasks = (data.tasks.completed || []).map((task) => ({
              ...task,
              startDate: task.start_date || null,
              endDate: task.end_date || null,
            }));

            this.upcomingTasks = (data.tasks.upcoming || []).map((task) => ({
              ...task,
              startDate: task.start_date || null,
              endDate: task.end_date || null,
            }));
          } else {
            console.error("No task data returned from the API.");
          }
        })
        .catch((error) => {
          console.error("Error fetching tasks:", error);
        });
    },
    async fetchPeople() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API}/organization-user/list/?organization_id=${this.getSelectedOrganization.organization_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        this.collaboratorsCount = data.count;
        this.people = data.data.map((item) => ({
          id: item.user.id,
          display_name: item.user.display_name,
        }));
      } catch (error) {
        console.error("Error fetching people:", error);
      }
    },
    updateGreeting() {
      const hour = new Date().getHours();

      if (hour >= 5 && hour < 12) {
        this.greeting = "Good Morning";
      } else if (hour >= 12 && hour < 17) {
        this.greeting = "Good Afternoon";
      } else if (hour >= 17 && hour < 20) {
        this.greeting = "Good Evening";
      } else {
        this.greeting = "Good Night";
      }
    },
    updateTime() {
      const now = new Date();
      const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      this.currentTime = now.toLocaleTimeString("en-US", options);
    },
    startTimeUpdate() {
      this.updateGreeting();
      this.updateTime();

      this.intervalId = setInterval(() => {
        this.updateTime();

        if (new Date().getSeconds() === 0) {
          this.updateGreeting();
        }
      }, 1000);
    },
    formatDateTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async projectPost(el) {
      const formProjectData = new FormData();
      formProjectData.append("name", el.target.elements.project_name.value);
      formProjectData.append("workspace", this.selectedWorkspace);
      formProjectData.append("description", el.target.elements.desc.value);
      formProjectData.append(
        "background_image",
        el.target.elements.bg_image.files[0]
      );

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API}/project/create/`,
          formProjectData,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        if (response.status === 200) {
          this.message = response.data.message;
          this.status = 200;
          setTimeout(() => {
            this.message = null;
          }, 2000);
          this.$refs.project_form.reset();
          this.toggleProjectOffcanvas();
          this.selectWorkspace(this.selectedWorkspace);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.message = error.response.data.message;
        } else {
          this.message = error.message;
        }

        this.status = error.response ? error.response.status : 500;

        setTimeout(() => {
          this.message = null;
        }, 5000);
      }
    },

    selectWorkspace(workspaceId) {
      this.selectedWorkspace = workspaceId;
    },
    async workspaceList() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/workspace/get/list/?organization__id=${this.getSelectedOrganization.organization_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.workspace_details = response.data.data;
            this.selectWorkspace(this.workspace_details[0].id);
          }
        })
        .catch((error) => {
          console.error("API call failed:", error);
        });
    },

    toggleProjectOffcanvas() {
      this.isProjectOffcanvasOpen = !this.isProjectOffcanvasOpen;
    },
    async getmemberid() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/team-member/list/?organization_user_id=${this.getOrgUser}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        if (response.status === 200) {
          this.user_member_id = response.data.data[0]?.id;
        } else {
          this.user_member_id = "";
          console.error(
            "Failed to retrieve team members:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },
    fetchProjects() {
      axios
        .get(
          `${process.env.VUE_APP_API}/project/get/list/?workspace__organization_id=${this.getSelectedOrganization.organization_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        )
        .then((response) => {
          this.projects = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching projects:", error);
        });
    },

    taskoverdueCount() {
      axios
        .get(
          `${process.env.VUE_APP_API}/task-detail/list/?assigned_to__organization_user_id=${this.getOrgUser}`
        )
        .then((response) => {
          if (response.data.status === 200 && response.data.data) {
            this.overdueCount = response.data.data.task_count.over_due;
          } else {
            this.overdueCount = 0;
            console.error("Unexpected response structure or status");
          }
        })
        .catch((error) => {
          console.error("Error fetching task count:", error);
        });
    },
    getCurrentDate() {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return new Date().toLocaleDateString("en-US", options);
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    createProject() {
      if (this.newProjectName) {
        this.projects.push({
          name: this.newProjectName,
          category: "New Category",
        });
        this.newProjectName = "";
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped>
.material-icons {
  font-size: 20px;
}
</style>
