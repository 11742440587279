import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    isAuthenticated: false,
    user: null,
    organization: null,
    error: null,
    orgUser: null,
    selectedOrganization: null,
    accessToken: null,
  },
  actions: {
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setSelectedOrganization({ commit }, org) {
      commit("setSelectedOrganization", org);
      commit("setOrgUser", org.organization_user_id);
    },
    async login({ commit }, { email, password, device_token }) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API}/api/user-login/`,
          { email, password, device_token }
        );
        console.log(response, "login success");
        if (response.data.status === 200) {
          commit("setUser", response.data.data.user);
          commit("setOrganization", response.data.data.organizations);
          commit("setIsAuthenticated", true);
          commit("setError", null);
          commit(
            "setOrgUser",
            response.data.data.organizations[0].organization_user_id
          );
          commit(
            "setSelectedOrganization",
            response.data.data.organizations[0]
          );
          commit("setAccessToken", response.data.data.access_token);

          console.log("access token", response.data.data.access_token);
        } else {
          commit("setError", response.data.message);
          setTimeout(() => {
            commit("setError", null);
          }, 3000);
          console.log("login response error", response.data);
        }
      } catch (error) {
        commit("setError", error.response?.data?.message) ||
          "An error occurred during login";
        commit("setError", error.message || "An error occurred during login");
        commit("setIsAuthenticated", false);
        commit("setUser", null);
        commit("setOrganization", null);
        commit("setOrgUser", null);
        commit("setSelectedOrganization", null);
        commit("setAccessToken", null);
      }
    },
    async signUp({ commit }, userData) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API}/api/user-organization-create/`,
          userData
        );

        if (response.status === 200) {
          commit("setUser", response.data.users);
          commit("setOrganization", response.data.organization);
          commit("setIsAuthenticated", true);
        }
      } catch (error) {
        console.error("Error during sign up:", error);
      }
    },
    logout({ commit }) {
      commit("setIsAuthenticated", false);
      commit("setUser", null);
      commit("setOrganization", null);
      commit("setOrgUser", null);
      commit("setSelectedOrganization", null);
      commit("setAccessToken", null);
    },
  },
  getters: {
    getUser: (state) => state.user,
    getOrganization: (state) => state.organization,
    isAuthenticated: (state) => state.isAuthenticated,
    getOrgUser: (state) => state.orgUser,
    getError: (state) => state.error,
    getSelectedOrganization: (state) => state.selectedOrganization,
    getAccessToken: (state) => state.accessToken,
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    setUser(state, user) {
      state.user = user;
    },
    setOrganization(state, organization) {
      state.organization = organization;
    },
    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setOrgUser(state, orgUser) {
      state.orgUser = orgUser;
    },
    setSelectedOrganization(state, selectedOrganization) {
      state.selectedOrganization = selectedOrganization;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
  },
  plugins: [createPersistedState({})],
});
