<template>
  <div class="flex gap-4 mx-5">
    <div
      v-if="archievedCard"
      class="bg-black z-[999] fixed top-0 left-0 flex justify-center items-center right-0 bottom-0 bg-opacity-10"
    >
      <div
        @click.stop
        class="fixed top-0 right-0 bg-slate-200 2xl:w-[300px] lg:w-[279px] md:w-[200px] h-full shadow-lg overflow-auto"
      >
        <div
          class="flex items-center justify-between bg-blue-600 rounded-lg relative text-white py-4 px-4"
        >
          <h1 class="text-center text-sm font-semibold">Archived Card List</h1>
          <button
            @click="closeArchievedCardCanvas"
            class="bg-red-500 h-8 text-white absolute right-3 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <ul class="2xl:w-[300px] lg:w-[279px] md:w-[200px] overflow-auto p-3">
          <li
            v-for="task in tasks"
            :key="task.id"
            class="relative rounded-xl my-4 bg-white shadow-md hover:shadow-lg transition-shadow duration-200 border border-gray-200 overflow-hidden w-full"
          >
            <div class="py-4 px-3">
              <h2
                class="text-gray-700 text-sm capitalize text-start flex justify-between items-center"
              >
                <span class="line-clamp-1">{{ task.name }}</span>
                <span class="text-xs text-gray-500"
                  >({{ task.taskCount }} tasks)</span
                >
              </h2>
              <hr class="my-2" />

              <div class="w-full">
                <button
                  @click="unArchiveCard(task.id)"
                  class="flex float-right items-center gap-x-2 text-sm font-medium text-gray-700 py-2"
                >
                  <svg
                    class="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12"
                      stroke="#1C274C"
                      stroke-width="1.5"
                    />
                    <path
                      d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14Z"
                      stroke="#1C274C"
                      stroke-width="1.5"
                    />
                    <path
                      d="M12 11L12 17M12 17L14.5 14.5M12 17L9.5 14.5"
                      stroke="#00000098"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span class="text-gray-800 mt-1 capitalize text-xs"
                    >Unarchive</span
                  >
                </button>
              </div>
            </div>
            <div
              v-if="task.showSuccess"
              class="text-green-800 text-sm mt-2 w-auto px-3"
            >
              {{ task.successMessage }}
            </div>
            <div
              v-if="task.showError"
              class="text-red-800 text-sm mt-2 w-auto px-3"
            >
              {{ task.errorMessage }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "ArchivedCardList",
  props: {
    showarchievedCard: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      archievedCard: this.showarchievedCard,
      tasks: [],
    };
  },
  created() {
    this.fetchCardDetails();
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getOrgUser",
      "getSelectedOrganization",
      "getAccessToken",
    ]),
  },
  methods: {
    async unArchiveCard(taskId) {
      try {
        const response = await axios.patch(
          `${process.env.VUE_APP_API}/project-card/update-destroy/${taskId}/`,
          { status: true },
          {
            params: {
              project_id: this.projectId,
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        const task = this.tasks.find((task) => task.id === taskId);
        if (task) {
          task.successMessage =
            response.data?.message || "Task successfully unarchived";
          task.showSuccess = true;

          setTimeout(() => {
            task.showSuccess = false;
          }, 3000);
        }

        await this.fetchCardDetails();
      } catch (error) {
        const task = this.tasks.find((task) => task.id === taskId);
        if (task) {
          task.errorMessage =
            error.response?.data?.message ||
            "Permission required to unarchive task.";
          task.showError = true;

          setTimeout(() => {
            task.showError = false;
          }, 3000);
        }

        console.error("Error unarchiving task:", error.message);
      }
    },

    async fetchCardDetails() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/project-card/archive/list/`,
          {
            params: {
              project_id: this.projectId,
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        if (response.status === 200 && Array.isArray(response.data?.data)) {
          this.tasks = response.data.data.map((card) => ({
            ...card,
            taskCount: card.task_count,
          }));
        } else {
          console.warn("Unexpected response format:", response.data);
          this.tasks = [];
        }
      } catch (error) {
        console.error("Error fetching card details:", error.message);
        this.tasks = [];
      }
    },
    closeArchievedCardCanvas() {
      this.$emit("close-form");
      this.$emit("refresh");
    },
  },
};
</script>
