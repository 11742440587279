<template>
  <div>
    <div>
      <div
        v-if="previewSubtaskDetails"
        class="bg-[#00000030] fixed left-0 top-0 right-0 h-full z-10 transition-all duration-500 ease-in-out"
      ></div>
      <div
        :class="[
          'fixed right-0 transition-transform top-0 z-30 h-full rounded-tl-lg bg-white w-[30rem] shadow-xl duration-300 ease-in-out',
          previewSubtaskDetails ? 'translate-x-0' : 'translate-x-full',
        ]"
      >
        <div class="h-screen flex flex-col">
          <div class="flex-1 overflow-y-auto">
            <div
              v-if="!editSubTaskDetails"
              class="text-start flex flex-col h-full gap-4"
            >
              <div
                class="border-b border-gray-400 flex items-center justify-between bg-blue-600 rounded-tl-lg text-white py-4 px-4"
              >
                <div class="text-xl">SubTask details</div>
                <div class="flex gap-x-3 items-center ml-auto">
                  <img
                    @click="editSubTask"
                    src="@/assets/edit.svg"
                    class="w-8 cursor-pointer filter invert"
                    alt="Edit icon"
                  />

                  <svg
                    @click="this.$emit('closeSubTaskCanvas')"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="bg-red-500 h-8 text-white font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex flex-col gap-4 px-6 bg-white">
                <div class="font-semibold taskTitle text-gray-800 text-xl">
                  {{ showSubtaskDetails.name }}
                </div>
                <div class="flex flex-col gap-3">
                  <div class="flex justify-between">
                    <div
                      class="font-semibold items-center flex gap-x-2 text-gray-800"
                    >
                      <!-- <span>Due date :</span> -->
                      <img src="@/assets/duetime.svg" class="w-6 h-5" alt="" />
                      <span
                        class="text-gray-600 font-normal"
                        :class="[
                          showSubtaskDetails.end_date
                            ? 'text-gray-600'
                            : 'text-gray-600 text-sm font-light',
                        ]"
                        >{{
                          showSubtaskDetails.end_date
                            ? formatDateTime(showSubtaskDetails.end_date)
                            : "No Due Date"
                        }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="showSubtaskDetails.document"
                    class="flex flex-col relative gap-1"
                  >
                    <img
                      :src="getSecureUrl(showSubtaskDetails.document)"
                      alt="Task Document"
                      class="min-w-full min-h-full border object-contain rounded-lg shadow-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-start">
              <div
                class="flex items-center justify-between bg-blue-600 rounded-l-lg text-white py-4 px-4"
              >
                <h5 class="mb-0 font-semibold text-medium leading-normal" id="">
                  Update Subtask
                </h5>

                <svg
                  @click="editSubTask"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="bg-red-500 h-7 text-white absolute right-5 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div class="flex-grow overflow-y-auto p-4 text-[.9rem]">
                <form
                  @submit.prevent="updateSubTask"
                  class="flex flex-col gap-5 bg-white rounded-2xl w-full max-w-lg mx-auto relative"
                >
                  <!-- Name Input -->
                  <div class="flex flex-col relative gap-2">
                    <label
                      for="sub_task_name"
                      class="text-md font-medium text-start text-gray-900"
                      >Subtask Name <span class="text-red-500">*</span></label
                    >
                    <input
                      type="text"
                      id="sub_task_name"
                      v-model="updateSubtaskDetails.name"
                      class="rounded-lg bg-slate-100 text-gray-700 px-4 py-2 w-full"
                      placeholder="Enter subtask name"
                    />
                  </div>

                  <!-- Start Date Input -->
                  <div class="flex flex-col gap-2">
                    <label
                      for="start_date"
                      class="text-md font-medium text-start text-gray-900"
                      >Start Date</label
                    >
                    <input
                      type="date"
                      id="start_date"
                      v-model="updateSubtaskDetails.start_date"
                      class="rounded-lg border border-gray-300 text-gray-700 px-4 py-2 bg-slate-100 focus:ringw-full"
                    />
                  </div>

                  <!-- End Date Input -->
                  <div class="flex flex-col gap-2">
                    <label
                      for="end_date"
                      class="text-md font-medium text-start text-gray-900"
                      >End Date</label
                    >
                    <input
                      type="date"
                      id="end_date"
                      v-model="updateSubtaskDetails.end_date"
                      class="rounded-lg border border-gray-300 text-gray-700 px-4 py-2 bg-slate-100 w-full"
                    />
                  </div>

                  <!-- Document Upload -->
                  <div class="flex flex-col gap-2">
                    <label
                      for="document"
                      class="text-md font-medium text-start text-gray-900"
                      >Upload Document</label
                    >
                    <input
                      type="file"
                      id="document"
                      @change="subTaskfileName"
                      class="rounded-lg border border-gray-300 bg-slate-100 text-gray-700 h-10 w-full"
                    />
                  </div>

                  <!-- Update Button -->
                  <div class="text-center pt-4">
                    <button
                      type="submit"
                      class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-5 py-2 rounded-lg font-medium shadow-md hover:bg-[#76bdeb] hover:text-white transition-all w-full sm:w-auto"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>

              <h1
                class="flex justify-center"
                :class="
                  messageType === 'success' ? 'text-green-600' : 'text-red-600'
                "
              >
                {{ message }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    previewSubtaskDetails: Boolean,
    subtask__Id: Number,
  },
  data() {
    return {
      showSubtaskDetails: {},
      isLoading: false,
      error: null,
      taskImage: "",
      editSubTaskDetails: false,
      updateSubtaskDetails: {
        name: "",
        start_date: "",
        end_date: "",
      },
    };
  },
  watch: {
    showSubtaskDetails: {
      handler(newVal) {
        this.updateSubtaskDetails = {
          name: newVal?.name || "",
          start_date: this.formatDate(newVal?.start_date) || "",
          end_date: this.formatDate(newVal?.end_date) || "",
        };
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getSelectedOrganization", "getAccessToken"]),
  },
  mounted() {
    this.getSubtaskDetailsList();
  },
  methods: {
    subTaskfileName(event) {
      this.taskImage = event.target.files[0];
    },
    async updateSubTask() {
      let formData = new FormData();

      formData.append("name", this.updateSubtaskDetails.name);
      formData.append("start_date", this.updateSubtaskDetails.start_date);
      formData.append("end_date", this.updateSubtaskDetails.end_date);
      formData.append("status", true);

      if (this.taskImage) {
        formData.append("document", this.taskImage);
      }

      try {
        const resp = await axios.patch(
          `${process.env.VUE_APP_API}/sub-task/update-destroy/${this.subtask__Id}/`,
          formData,

          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (resp.status === 200) {
          this.message = resp.data.message;
          this.messageType = "success";
          this.updatemessage = true;

          setTimeout(() => {
            this.updatemessage = false;
            this.editSubTaskDetails = false;
          }, 2000);
          this.getSubtaskDetailsList();
        }
      } catch (error) {
        console.error("Update failed:", error);

        this.message = error.response?.data?.message || "An error occurred";
        this.messageType = "error";
        this.updatemessage = true;

        setTimeout(() => {
          this.updatemessage = false;
        }, 2000);
      }
    },
    editSubTask() {
      this.editSubTaskDetails = !this.editSubTaskDetails;
    },
    getSecureUrl(url) {
      if (url.startsWith("http:")) {
        return url.replace("http:", "https:");
      }
      return url;
    },
    async getSubtaskDetailsList() {
      this.isLoading = true;
      this.error = null;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/sub-task/list/?id=${this.subtask__Id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        if (response.status === 200) {
          this.showSubtaskDetails = response.data.data[0];
        } else {
          throw new Error(
            response.data.message || "Failed to fetch subtask details."
          );
        }
      } catch (error) {
        this.error = error.message || "An unexpected error occurred.";
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      return d.toLocaleDateString("en-CA");
    },
    formatDateTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month}`;
    },
  },
};
</script>
<style scoped></style>
