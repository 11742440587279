<template>
  <div>
    <!-- Workspaces Section -->
    <aside
      class="relative text-[#76bdeb] h-[98%] bg-white p-2 transition-all duration-300 shadow-2xl overflow-y-auto w-[250px] rounded-lg border border-gray-200"
    >
      <div class="menu mt-5 flex flex-col gap-2 justify-center items-center">
        <div class="py-3 text-start text-sm">
          <RouterLink
            to="/homeview"
            class="p-2 w-full flex gap-2 items-center cursor-pointer rounded-md text-gray-700 border border-blue-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
            <p>Home</p>
          </RouterLink>
          <h1
            class="p-2 w-full flex gap-2 items-center cursor-pointer rounded-md text-gray-700 border border-blue-500 mt-3"
            @click="toggleAccordion('workspaces')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
              />
            </svg>

            <p>Workspaces</p>
            <button
              class="rounded text-xs font-medium px-2 ml-auto"
              @click.stop="toggleOffcanvas"
            >
              <i class="text-purple-900 text-xl font-bold"> +</i>
            </button>

            <i
              :class="workspacesOpen ? 'rotate-180' : 'rotate-0'"
              class="transition-transform duration-300 text-purple-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </i>
          </h1>

          <!-- Workspace list (accordion content) -->
          <ul
            v-show="workspacesOpen"
            class="flex flex-col gap-2 p-1 text-start"
          >
            <div
              v-for="workspace in workspace_list"
              :key="workspace.id"
              class="flex flex-col"
            >
              <div
                @click="toggleWorkspace(workspace.id)"
                :class="{
                  'bg-[#e0f0fb] border-[#76bdeb]':
                    selected_workspace == workspace.id,
                }"
                class="p-2 flex gap-2 items-center cursor-pointer shadow-lg rounded-md text-gray-700 border border-gray-200"
              >
                <p class="" @click="openprojectList()">{{ workspace.name }}</p>
                <i
                  :class="workspace.isOpen ? 'rotate-180' : 'rotate-0'"
                  class="transition-transform duration-300 text-purple-900 ml-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </i>
              </div>
              <div v-if="workspace.isOpen" class="ml-4 mt-2">
                <div
                  v-for="project in workspace.projects"
                  :key="project.id"
                  class="mb-2 p-2 z-40 flex gap-2 items-center cursor-pointer shadow-lg rounded-md text-gray-700 border border-gray-200"
                  @click="
                    $emit('workspaceList', workspace.id),
                      (this.selected_workspace = workspace.id)
                  "
                  :class="{
                    'bg-[#e0f0fb] border-[#76bdeb]':
                      selected_workspace == workspace.id,
                  }"
                >
                  <div class="">
                    <p>{{ project.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div
        class="absolute bottom-4 left-0 flex items-center justify-center w-full"
      >
        <div>
          <button
            @click="isPopUp = true"
            class="px-6 py-1 border border-gray-300 rounded-md text-gray-700"
          >
            Invite Members
          </button>
        </div>
      </div>
    </aside>

    <!-- Add Workspace Form (offcanvas) -->
    <div
      :class="[
        'fixed right-0 top-0 z-40  h-full bg-white shadow-xl transition-transform duration-300 ease-in-out',
        isOffcanvasOpen ? 'translate-x-0' : 'translate-x-full',
      ]"
    >
      <div class="flex items-center justify-between pt-4 px-4">
        <h5 class="mb-0 font-semibold leading-normal" id="offcanvasRightLabel">
          Let's build a Workspace
        </h5>
        <button
          @click="toggleOffcanvas"
          type="button"
          class="box-content border-none opacity-50 hover:text-red-600 focus:opacity-100 focus:outline-none focus:ring-2 rounded-full p-1 text-lg"
          aria-label="Close"
        >
          <span class="text-xl"> ✕ </span>
        </button>
      </div>

      <div class="offcanvas-body flex-grow overflow-y-auto p-4">
        <form
          ref="workspace_ref"
          @submit.prevent="workSpacePost"
          class="flex flex-col gap-4"
        >
          <div class="flex flex-col gap-1 items-start">
            <label for="org_id">Organization*</label>
            <select
              id="org_id"
              class="h-10 w-full text-gray-700 px-2 pt-0.5 rounded-md focus:outline-none border focus:ring-2 focus:ring-[#76bdeb]"
              required
            >
              <option value="" disabled selected>Select a Organization</option>
              <option
                :value="organization.id"
                v-for="organization in organization_list"
                :key="organization.id"
              >
                {{ organization.name }}
              </option>
            </select>
          </div>
          <div class="flex flex-col gap-1 items-start">
            <label for="workspace_name">Workspace Name*</label>
            <input
              type="text"
              id="workspace_name"
              placeholder="Workspace Name"
              required
              class="h-10 w-full text-gray-700 px-2 pt-0.5 rounded-md focus:outline-none border focus:ring-2 focus:ring-[#76bdeb]"
            />
          </div>
          <div class="flex flex-col gap-1 items-start">
            <label for="workspace_img">Workspace Image*</label>
            <input
              type="file"
              id="workspace_image"
              required
              class="file-upload"
            />
          </div>

          <div class="flex flex-col gap-1 items-start">
            <label for="desc">Workspace Description</label>
            <textarea
              id="desc"
              cols="30"
              rows="4"
              placeholder="Describe your workspace..."
              class="rounded-md w-full text-gray-800 px-2 pt-0.5 border focus:outline-none bo focus:ring-2 focus:ring-[#76bdeb]"
            ></textarea>
          </div>
          <div class="flex justify-center">
            <button
              type="submit"
              class="bg-soft-primary border border-primary text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-all hover:bg-primary shadow-lg"
            >
              Add Workspace
            </button>
          </div>
        </form>
      </div>
    </div>
    <InviteMemberVue v-if="isPopUp" @closePopUp="this.isPopUp = false" />
  </div>
</template>

<script>
import axios from "axios";
import InviteMemberVue from "@/components/InviteMember.vue";

export default {
  name: "SideNav",
  props: ["organization_list", "workspaceDetails"],
  data() {
    return {
      isOffcanvasOpen: false,
      workspace_list: [],
      selected_workspace: null,
      workspacesOpen: true,
      new_project_List: [],
      isPopUp: false,
    };
  },
  components: { InviteMemberVue },
  created() {
    this.workspace_list = this.workspaceDetails.map((workspace) => ({
      ...workspace,
      isOpen: false,
      projects: [],
    }));
    if (this.workspace_list.length > 0) {
      this.selected_workspace = this.workspace_list[0].id;
    }
  },
  methods: {
    async toggleWorkspace(workspaceId) {
      const workspace = this.workspace_list.find((w) => w.id === workspaceId);
      if (workspace) {
        workspace.isOpen = !workspace.isOpen;
        this.selected_workspace = workspaceId;

        if (workspace.isOpen && workspace.projects.length === 0) {
          try {
            const response = await axios.get(
              `${process.env.VUE_APP_API}/project/list/?workspace=${workspaceId}`
            );

            if (response.data && response.data.status === 200) {
              workspace.projects = response.data.data.map((project) => ({
                ...project,
                isOpen: false,
              }));
            } else {
              console.error(`Error fetching projects: ${response.data.status}`);
              workspace.projects = [];
            }
          } catch (error) {
            console.error("Error fetching projects:", error);
            workspace.projects = [];
          }
        }
      }
    },
    async openprojectList(id) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/project/list/?workspace=${id}`
        );

        if (response.data && response.data.status === 200) {
          console.log(response.data);
          if (response.data.data && response.data.data.length > 0) {
            this.new_project_List = response.data.data;
          } else {
            this.new_project_List = [];
          }
        } else {
          this.new_project_List = [];
          console.error(
            `Error: Received status ${response.data.status || response.status}`
          );
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },

    async workSpacePost(el) {
      const formData = new FormData();
      formData.append("organization", el.target.elements.org_id.value);
      formData.append("name", el.target.elements.workspace_name.value);
      formData.append("desc", el.target.elements.desc.value);
      formData.append("image", el.target.elements.workspace_image.files[0]);

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API}/workspace/create/`,
          formData
        );
        console.log(response);
        this.$refs.workspace_ref.reset();
        this.$emit("workspaceCallBack", this.selected_workspace);

        // Add the new workspace to the list
        this.workspace_list.push({
          ...response.data,
          isOpen: false,
          projects: [],
        });
        this.toggleOffcanvas();
      } catch (error) {
        if (error.response) {
          console.error("Response data:", error.response.data);
        }
      }
    },

    toggleOffcanvas() {
      this.isOffcanvasOpen = !this.isOffcanvasOpen;
    },

    toggleAccordion(section) {
      if (section === "workspaces") {
        this.workspacesOpen = !this.workspacesOpen;
      }
    },
  },
};
</script>

<style></style>
