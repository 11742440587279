<template>
  <div v-if="userAddForm" class="fixed inset-0 z-[999] bg-black bg-opacity-10">
    <div
      class="fixed top-0 right-0 bg-white w-full max-w-sm h-full shadow-lg transform transition-transform duration-300"
    >
      <form
        @submit.prevent="saveUser"
        class="flex flex-col gap-y-4 relative h-full"
      >
        <div
          class="flex items-center justify-between bg-blue-600 text-white py-4 px-4 rounded-t-lg"
        >
          <h1 class="text-lg font-semibold">Add User</h1>
          <svg
            @click="closeAddUser"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="bg-red-500 h-8 text-white font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>

        <!-- Form Fields -->
        <div class="flex-grow overflow-y-auto px-4 py-2 space-y-4 text-start">
          <div>
            <label for="name" class="block text-gray-700 text-sm mb-2">
              Name <b class="text-red-500">*</b>
            </label>
            <select
              v-model="name"
              id="name"
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
            >
              <option value="">Select Name</option>
              <option v-for="user in userList" :key="user.id" :value="user.id">
                {{ user.display_name }}
              </option>
            </select>
          </div>

          <div>
            <label for="category" class="block text-gray-700 text-sm mb-2">
              Category <b class="text-red-500">*</b>
            </label>
            <select
              v-model="category"
              id="category"
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
            >
              <option value="">Select Category</option>
              <option
                v-for="category in categoryList"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </div>

          <div>
            <label for="role" class="block text-gray-700 text-sm mb-2">
              Role
            </label>
            <select
              v-model="role"
              id="role"
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
            >
              <option value="">Select Role</option>
              <option v-for="role in roleList" :key="role.id" :value="role.id">
                {{ role.name }}
              </option>
            </select>
          </div>

          <div>
            <label for="level" class="block text-gray-700 text-sm mb-2">
              Level
            </label>
            <select
              v-model="level"
              id="level"
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
            >
              <option value="">Select Level</option>
              <option value="1">Level 1</option>
              <option value="2">Level 2</option>
              <option value="3">Level 3</option>
            </select>
          </div>
        </div>

        <div class="py-4 text-center">
          <button
            type="submit"
            class="bg-blue-600 text-white w-36 py-2 rounded-lg text-sm font-medium transition-all hover:bg-blue-700 shadow-lg"
          >
            Submit
          </button>
        </div>
        <div
          v-if="message"
          :class="status === 200 ? 'text-green-500' : 'text-red-500'"
        >
          {{ message }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: {
    showAddUser: {
      type: Boolean,
    },
  },

  data() {
    return {
      userAddForm: this.showAddUser,
      name: "",
      category: "",
      role: "",
      level: "",
      roleList: [],
      userList: [],
      categoryList: [],
      message: null,
      status: null,
    };
  },

  mounted() {
    this.fetchUserList();
    this.fetchCategories();
    this.fetchRole();
  },

  computed: {
    ...mapGetters(["getSelectedOrganization", "getAccessToken", "getUser"]),
  },

  methods: {
    async fetchRole() {
      try {
        this.loading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_API}/user-role/list/`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        if (response.data.success) {
          this.roleList = response.data.data;
          this.successMessage =
            response.data.message || "Role created successfully.";
        } else {
          this.errorMessage = response.data.message || "Failed to create role.";
        }
      } catch (error) {
        this.errorMessage = "An error occurred while creating the role.";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/role-category/list/`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        this.categoryList = response.data.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchUserList() {
      this.loading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API}/users/`, {
          headers: {
            Authorization: `Bearer ${this.getAccessToken}`,
          },
        });

        this.userList = response.data;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    async saveUser() {
      try {
        this.loading = true;
        const formData = {
          user: this.name,
          organization: this.getSelectedOrganization.organization_id,
          user_role: this.role,
          role_category: this.category,
          level: this.level,
        };

        const response = await axios.post(
          `${process.env.VUE_APP_API}/organization-user/create/`,
          formData,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          this.$emit("formSubmit", response.data.data);
          this.message = response.data.message;
          this.status = 200;
          setTimeout(() => (this.message = null), 2000);
          this.$emit("refresh");
          this.closeAddUser();
        }
      } catch (error) {
        this.message = error.response?.data?.message;
        setTimeout(() => (this.message = null), 5000);
        console.error("Error creating user:", error);
      }
    },

    closeAddUser() {
      window.location.reload();
      this.$emit("refresh");
      this.$emit("close-form");
    },

    resetForm() {
      this.name = "";
      this.category = "";
      this.role = "";
      this.level = "";
      this.errorMessage = "";
      this.successMessage = "";
    },
  },
};
</script>
